import { useState } from "react";
import ReactImageVideoLightbox from "react-image-video-lightbox";

const Gallery = ({ lower, text, count, id }) => {
	const [open, setOpen] = useState(false),
  		  [currentIndex, setCurrentIndex] = useState(0),
    	  images = [];
	const openLightbox = (index) => {
		setCurrentIndex(index);
		setOpen(true);
	};	 
	if(lower === "o-mne") {
		images.push(
			{
				url: require(`../themes/frontend/video/video-1.mp4`),
				thumb: require(`../themes/frontend/video/video-1.webp`),
				title: "O mně",
				type: "video"
			}
		) 
		images.push(
			{
				url: require(`../themes/frontend/video/video-2.mp4`),
				thumb: require(`../themes/frontend/video/video-2.webp`),
				title: "O mně",
				type: "video"
			}
		) 
		images.push(
			{
				url: require(`../themes/frontend/video/video-3.mp4`),
				thumb: require(`../themes/frontend/video/video-3.webp`),
				title: "O mně",
				type: "video"
			}
		) 
		images.push(
			{
				url: require(`../themes/frontend/video/video-4.mp4`),
				thumb: require(`../themes/frontend/video/video-4.webp`),
				title: "O mně",
				type: "video"
			}
		) 
		images.push(
			{
				url: require(`../themes/frontend/video/video-5.mp4`),
				thumb: require(`../themes/frontend/video/video-5.webp`),
				title: "O mně",
				type: "video"
			}
		) 
		images.push(
			{
				url: require(`../themes/frontend/video/video-6.mp4`),
				thumb: require(`../themes/frontend/video/video-6.webp`),
				title: "O mně",
				type: "video"
			}
		) 
		images.push(
			{
				url: require(`../themes/frontend/video/video-7.mp4`),
				thumb: require(`../themes/frontend/video/video-7.webp`),
				title: "O mně",
				type: "video"
			}
		) 
		images.push(
			{
				url: require(`../themes/frontend/video/video-8.mp4`),
				thumb: require(`../themes/frontend/video/video-8.webp`),
				title: "O mně",
				type: "video"
			}
		) 
		images.push(
			{
				url: require(`../themes/frontend/video/video-9.mp4`),
				thumb: require(`../themes/frontend/video/video-9.webp`),
				title: "O mně",
				type: "video"
			}
		) 
	}
    for (var i = 1; i <= `${count}`; i++) {
        images.push(
            {
                url: require(`../themes/frontend/img/gallery/${lower}/${lower}-${i}.webp`),
                thumb: require(`../themes/frontend/img/gallery/${lower}/${lower}-${i}_thumb.webp`),
                title: `${text}`,
				type: "photo"
            }
        )
    }

    return (
        <div id={id} className="gallery">
            <div className="article-content left" data-title="Fotogalerie">
				{images.map((_im, idx) => 
                    <span className="gallery-image" key={idx} onClick={() => openLightbox(idx)}>
                        <span className="gallery-link">
                            <img src={_im.thumb} alt={_im.title} width="512" height="512" />
                        </span>
                    </span>
                )}
				{open && (
					<ReactImageVideoLightbox
						data={images}
						startIndex={currentIndex}
						onCloseCallback={() => setOpen(false)}
					/>
				)}
            </div>
        </div> 
    );
}

export default Gallery;
