import Use from "./Use.js";
import Accordion from "./Accordion.js";
import Images from "./Images";
import ArticleContent from "./ArticleContent";
import Arrow from "./Arrow";
import GalleryWrapper from "./GalleryWrapper";
import Gallery from "./Gallery";

const OcniLinky = () => {
    return (
        <>
            <Use title="Oční linky" description="Permanentní make-up Veronika Teplice nabízí techniku tetování očních linek jak v řasové linii, tak i stínované oční linky." /> 
            <article className="padding">
                <Images />
                <ArticleContent heading="Oční linky" />
                <Arrow url="#ocni-linky" text="Oční linky" />
            </article>
            <article id="ocni-linky" className="padding">
                <div className="article-content right padding" data-title="Důležité informace">
                    <h2>Oční linky</h2>
                    <ul>
                        <li className="accordion">
                            <span onClick={Accordion}>Co je mikropigmentace očních linek?</span>
                            <ul className="panel">
                                <li>Je to precizní zapravování speciálního pigmentu pod kůži, oblasti řas a horních víček.</li>
                                <li>Díky mikropigmentaci očních linek vypadá oko výrazněji, i když nejste právě nalíčená.</li>
                                <li>Jsou tak výhodou pro ženy, které se nelíčí vůbec nebo mají své řasy velmi světlé.</li>
                                <li>Tetování linek vytváří dojem hustějších a černějších řas.</li>
                                <li>Z estetických důvodů provádím tetování očních linek pouze u horních víček.</li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Jaké máme styly očních linek?</span>
                            <ul className="panel">
                                <li>Rozlišujeme několik stylů tetování očních linek podle toho, kam se pigment přesně umístí.</li>
                                <li>Provádím tyto dva styly:
                                    <ul>
                                        <li>Natural - pouze v řasové linii
                                            <ul>
                                                <li>Je to nejoblíbenější a nejpřirozenější provedení očních linek, kdy se zapravuje pigment přímo do linie mezi řasy.</li>
                                            </ul>
                                        </li>
                                        <li>Shading - stínované
                                            <ul>
                                                <li>Oční linka vedená v řasách i nad, která je v různých intenzitách stínovaná do ztracena.</li>
                                                <li>Oko je velmi zvýrazněné a působí dojmem nalíčení kouřovými stíny.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Jak bude probíhat má návštěva?</span>
                            <ul className="panel">
                                <li>Nejdříve si s kientkou domluvíme styl, který se k ní bude nejvíce hodit, musíme brát na vědomí také stav kůže okolo oka a výraz klientky.</li>
                                <li>Poté si předkreslíme tvar, pokud budeme linkami zasahovat i mimo řasovou linii.</li>
                                <li>Zde bereme v úvahu, že nám linky mají oko rozzářit a pozvednout a přihlížíme i k faktu, že kůže v okolí oka bude časem klesat.</li>
                                <li>Po domluvě aplikuji anestetika, pak aplikuji barvu, místo aplikace ošetřím a nakonec klientce dám pokyny k péči v tištěné podobě.</li>
                                <li>Celá procedura trvá přibližně 60 - 90 minut.</li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Co bych měla před zákrokem vědět?</span>
                            <ul className="panel">
                                <li>Na proceduru přijďte bez doprovodu. Tím je myšleno bez dětí, kamarádů, zvířátek, kočárků a dalších rodinných i nerodinných příslušníků. :-)</li>
                                <li>Při mikropigmentaci očních linek nesmíte mít nalepené žádné umělé řasy a to minimálně týden.</li>
                                <li>Na zákrok byste měla přijít odlíčená.</li>
                                <li>Nesmíte mít při aplikaci kontaktní čočky.</li>
                                <li>Minimálně tři týdny před zákrokem nepoužívejte žádná séra podporující růst řas.</li>
                                <li>Přijďte dostatečně odpočatá a vyspalá bez otoku očí.</li>
                                <li>U očních linek je pro dosažení sytého efektu vždy nutná korekce.</li>
                                <li>Na proceduru přijďte odpočatá, najezená a napitá.</li>
                                <li>V den procedury nepijte alkohol, kávu a neberte analgetika.</li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Pro koho není aplikace vhodná?</span>
                            <ul className="panel">
                                <li>Pokud trpíte jakoukoliv z níže popsaných kontraindikací, není možné zákrok provést:
                                    <ul>
                                        <li>cukrovka s aplikací inzulínu</li>
                                        <li>kardiostimulátor</li>
                                        <li>srdeční choroby</li>
                                        <li>cévní choroby</li>
                                        <li>snížená srážlivost krve</li>
                                        <li>léky na ředění krve</li>
                                        <li>snížená imunita</li>
                                        <li>užívání antibiotik</li>
                                        <li>horečnaté stavy</li>
                                        <li>akutní onemocnění či nachlazení</li>
                                        <li>solární erytém (spálení)</li>
                                        <li>kožní nemoc - dermatitida, vyrážka (včetně akutní alergické) apod.</li>
                                        <li>rakovina v aktivním stádiu či rakovina kůže v jakékoliv fázi</li>
                                        <li>epilepsie</li>
                                        <li>HIV či jiná přenosná nemoc</li>
                                        <li>alergie na kosmetické látky, alergie na anestetika, alergie na prvotně aplikovaný pigment</li>
                                        <li>těhotenství a kojení</li>
                                        <li>příliš kapilární pleť</li>
                                        <li>keloidní či hypertrofické jizvy</li>
                                        <li>psychické onemocnění či celková psychická nepohoda či úzkost</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Bolí to?</span>
                            <ul className="panel">
                                <li>Před i během zákroku používám dva druhy anestetik a sanžím se, aby pro klientku byl průběh co nejvíce pohodlný</li>
                                <li>Pigment zapracuji šetrně a stejně tak se chovám i k okolí oka, jelikož je zde velice citlivá kůže.</li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Jak pečovat o linky?</span>
                            <ul className="panel">
                                <li>Ať už se rozhodnete pro jakoukoliv proceduru, při které je porušována integrita kůže, tak berte, prosím, na vědomí, že se k ní vážou různá pravidla a omezení. Nedodržením pokynů můžete narušit proces hojení a negativně tak ovlivnit výsledný efekt.</li>
                                <li>14 dní po proceduře NENAVŠTĚVUJTE sauny, solária, bazény a koupaliště, přímé slunce, fitness centra nebo jiná sportoviště.</li>
                                <li>Podrobné pokyny k péči o ošetřené místo ode mne obdržíte v písemné podobě.</li>
                            </ul>
                        </li>
                        <li className="accordion has-pricelist">
                            <span onClick={Accordion}>Kolik to stojí?</span>
                            <ul className="panel">
                                <li className="pricelist">
                                    <ul className="pricelist-inner">
                                        <li className="pricelist-row">
                                            <span>Natural - pouze v řasové linii</span>
                                            <span><strong>3500 Kč + korekce 1000 Kč</strong></span>
                                        </li>
                                        <li className="pricelist-row">
                                            <span>Shading - stínované</span>
                                            <span><strong>4500 Kč + korekce 1000 Kč</strong></span>
                                        </li>
                                    </ul>
                                </li>
                                <li>Cena zahrnuje
                                    <ul>
                                        <li>Konzultaci o průběhu procedury, kde se můžete zeptat na vše, co Vás zajímá.</li>
                                        <li>V případě stínovaných linek zhodnocení a vyměření tvaru tak, aby co nejvíce podtrhoval Vaši krásu.</li>
                                        <li>Dále výběr toho nejvhodnějšího pigmentu pro co nejpřirozenější vzhled a samozřejmě v té nejvyšší kvalitě.</li>
                                        <li>Samotnou proceduru, kde se budu snažit, abyste se cítila co nejvíce pohodlně.</li>
                                        <li>Také předání informací ohledně následné péče.</li>
                                        <li>Samozřejmostí jsou detailní pokyny k péči v tištěné podobě.</li>
                                        <li>A v neposlední řadě následné poradenství v případě dotazů.</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <br /><br />
                    <h3>Nepřehlédněte!</h3>
                    <ul>
                        <li>Mé milé klientky, při objednání si s Vámi domlouvám zároveň termín korekce a to hlavně proto, abyste na ni přišly v tu nejvhodnější dobu a také s ohledem na obsazenost termínů. Pokud budete chtít termín změnit, tak prosím počítejte s tím, že další volný termín může být za delší dobu a linky už budou potřebovat více úprav. Zbytečně tím můžete snižovat kvalitu vyhojených výsledků. Proto Vás vždy prosím, abyste si daný termín řádně promyslely, abychom předešly zbytečným komplikacím.</li>
                        <li>Pokud budete přeci jen potřebovat změnit termín, učiňte tak nejdéle 24 hodin před původním termínem korekce. V opačném případě Vám bude ke korekci účtována navíc částka 500 Kč. Není to nic osobního, jen jsem v tu chvíli na Vaši korekci potřebovala dvojnásobek již započteného času. Pevně věřím, že mi rozumíte.</li>
                        <li>Cena korekce platí pouze pro korekce uskutečněné do 3 měsíců od první aplikace. Pokud budete korekci potřebovat později, cena může být navýšena.</li>
                        <li>Vyhrazuji si právo na výběr zálohy za objednanou proceduru, která je nevratná, pokud změnu termínu neohlásíte nejpozději 48 hodin předem.</li>
                        <li>Platba pouze v hotovosti!</li>
                    </ul>
                </div>
            </article>
            <GalleryWrapper>
                <Gallery lower="ocni-linky" text="Oční linky" count="9" />  
            </GalleryWrapper>     
        </>
    );
}

export default OcniLinky;
