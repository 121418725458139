import Use from "./Use.js";
import Accordion from "./Accordion.js";
import Images from "./Images";
import ArticleContent from "./ArticleContent";
import Arrow from "./Arrow";
import Img from "./Img";

const Poradna = () => {
    return (
        <>
            <Use title="Poradna" description="V této rubrice se pokusím vysvětlit nejčastější dotazy a problémy, se kterými se můžete setkat v oblasti PMU a které Vás nejvíce zajímají." /> 
            <article className="padding">
                <Images />
                <ArticleContent heading="Poradna" />
                <Arrow url="#poradna" text="Poradna" />
            </article>
            <article id="poradna" className="padding">
                <div className="article-content left padding" data-title="Důležité informace">
                    <h2>Poradna</h2>
                    <ul>
                        <li>V této rubrice se pokusím vysvětlit nejčastější dotazy a problémy, se kterými se můžete setkat v oblasti PMU a které Vás nejvíce zajímají.</li>
                        <li>Pokud Vás něco zajímá nebo byste chtěla nějaké téma blíže probrat, neváhejte mě kontaktovat. Budu se těšit na Vaše nápady.</li>
                    </ul>
                    <br /><br />
                    <h3>Rubriky</h3>
                    <ul>
                        <li className="accordion">
                            <span onClick={Accordion}>Obočí</span>
                            <ul className="panel">
                                <li>Příklad č. 1
                                    <ul>
                                        <li>A zase naše oblouky :-). Opravdu už se nenosí. Zase platí pravidlo "Co nejvíce přirozeně". Obočí má svoji základnu vždy rovnou, takže nevytrhávejte obočí zespoda do obloučku. Budete působit udiveným dojmem.</li>
                                        <Img category="pudrove-oboci" number="1" alt="Příklad č. 1" />
                                    </ul>
                                </li>
                                <li>Příklad č. 2
                                    <ul>
                                        <li>Barevnost obočí je důležitá. To, že žena nosí černé vlasy a černé linky neznamená, že má mít i černé obočí. Vím, že jde o zvyk, ale věřte, že obočí má být vždy hnědé. Žena pak nepůsobí tak tvrdě a zjemní to celý její výraz. Toto obočí je před korekcí.</li>
                                        <Img category="pudrove-oboci" number="2"  alt="Příklad č. 2" />
                                    </ul>
                                </li>
                                <li>Příklad č. 3
                                    <ul>
                                        <li>Obočí proklatě nízko. Někdy ženám může obočí podrůstat pod nadočnicový oblouk a aby zachovaly šířku, tak ho trhají seshora. To, prosím, nikdy nedělejte. Budete působit zamračeným dojmem a trháním obočí shora ztratíte přirozený tvar.</li>
                                        <Img category="pudrove-oboci" number="3"  alt="Příklad č. 3" />
                                    </ul>
                                </li>
                                <li>Příklad č. 4
                                    <ul>
                                        <li>Vždy při úpravě obočí pamatujte na jedno základní pravidlo. Pro mne jedno z nejdůležitějších. Koncový bod obočí nikdy nesmí být níž než začátek obočí. V některých případech může být ve stejné úrovni, ale nikdy ne pod. Výraz obličeje působí smutně a přidává roky. Naopak zvednutím konců, což většinou při PMU dělám, dokáži neskutečně rozzářit a omladit celý výraz obličeje. Tento trik umí někdy udělat více služby než operace víček.</li>
                                        <Img category="pudrove-oboci" number="4"  alt="Příklad č. 4" />
                                    </ul>
                                </li>
                                <li>Příklad č. 5
                                    <ul>
                                        <li>Když se chcete objednat na obočí a už jste někdy PMU podstoupila, tak chci většinou Vaše fotky. Stává se totiž, že řeknete, že už je Vaše obočí vybledlé, ale z hlediska správného oživení tomu tak v žádném případě není. Na fotkách jsou údajně světlá obočí. Nic zákaznicím nevyčítám, nejsou to stylistky, ale toto nejde oživit. Jen u mě ztratí čas a peníze bez výsledku. V těchto případech nabízím odstranění. Je to delší proces, ale stojí za to.</li>
                                        <Img category="pudrove-oboci" number="5" alt="Příklad č. 5" />
                                    </ul>
                                </li>
                                <li>Příklad č. 6
                                    <ul>
                                        <li>Pokud si uděláte širší obočí, než je šířka Vašeho přirozeného růstu, tak můžete působit mužněji a nepřirozeně. Zde jsem jen přizpůsobila tvar přirozenému růstu a podtrhla ženskost klientky.</li>
                                        <Img category="pudrove-oboci" number="6" alt="Příklad č. 6" />
                                    </ul>
                                </li>
                                <li>Příklad č. 7
                                    <ul>
                                        <li>Tloušťka obočí má svá pravidla. U kořene nosu je nejširší a pomalu se ke konečku ztenčuje. Takto úzké obočí a ještě stejné po celé délce a v černé barvě působí nevzhledně a nepřirozeně. Toto byla krásná proměna. Obočí je před korekcí.</li>
                                        <Img category="pudrove-oboci" number="7" alt="Příklad č. 7" />
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Rty</span>
                            <ul className="panel">
                                <li>Příklad č. 8
                                    <ul>
                                        <li>Vždy klientkám říkám, že pomocí PMU se rty nezvětšují. Ptají se proč. Tak přesně proto. Zde jsou příklady, jak to dopadá. Pigment mimo kůži na rtech se díky pórům rozpíjí a nedrží tak tvar ani barvu. Musí se pak přistoupit k odstranění.</li>
                                        <Img category="rty" number="1" alt="Příklad č. 8" />
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Oční linky</span>
                            <ul className="panel">
                                <li>Příklad č. 9
                                    <ul>
                                        <li>Své klientky upozorňuji, že se linky nemůžou vždy tetovat do míst, kde si je malují. Na oku jsou tzv. kritické zóny, kam se zásadně pigment nevpravuje. Zde často dochází k migraci pigmentu (rozpití). Linka pak nevypadá vůbec hezky. Pamatujte, že jehla není tužka ani štětec.</li>
                                        <Img category="ocni-linky" number="1" alt="Příklad č. 9" />
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </article>
        </>
    );
}

export default Poradna;
