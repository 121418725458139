import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

const Use = ({ clas, title, description }) => {
    useEffect(() => {
        function fetchBusinesses() {
            window.scrollTo(0, 0);
        
            document.querySelectorAll("meta[name*='title'], meta[property*='title']").forEach(anchor => {
                anchor.setAttribute("content", `${title} | Permanentní make-up Veronika`);
            });
            document.querySelectorAll("meta[name*='description'], meta[property*='description']").forEach(anchor => {
                anchor.setAttribute("content", `${description}`);
            });
            document.querySelector("meta[property='og:url']").setAttribute("content", window.location.href);
            document.querySelector("link[rel='canonical']").setAttribute("href", window.location.href);
        }
        fetchBusinesses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (     
        <Helmet>  
            <title>{title} | Permanentní make-up Veronika</title>
            <body className={clas} />  
        </Helmet> 
    );
}

export default Use;
