import Use from "./Use.js";
import Images from "./Images";
import ArticleContent from "./ArticleContent";
import Arrow from "./Arrow";

const NotFound = () => {
    return (
        <>
            <Use title="Stránka nenalezena" description="Veronika Zelenková, Masarykova tř. 2475/57, 415 01 Teplice, Ústecký kraj. Permanentní make-up je tetování speciálními pigmenty určených do oblasti obličeje jako jsou rty, oční linky a pudrové obočí. Připravuji pro Vás také metodu Hair Stroke, která je šetrnější než microblading obočí a napodobuje vzhled laminace obočí." /> 
            <article id="stranka-nenalezena" className="padding">
                <Images />
                <ArticleContent heading="Stránka nenalezena" />
                <Arrow url="#footer" text="Najít cestu" />
            </article>
        </>
    );
}

export default NotFound;
