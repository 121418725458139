import { Link } from "react-router-dom";

const MenuLang = () => {
    return (
        <>
            {false ? 
                <div className="menu-lang">
                    <ul>
                        <li><Link to="/cs" title="Česky">CZ</Link></li>
                        <li><Link to="/en" title="English">EN</Link></li>
                        <li><Link to="/de" title="Deutsch">DE</Link></li>
                    </ul>
                </div>
            : ""}
        </>  
    );
}

export default MenuLang;
