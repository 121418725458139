const GalleryWrapper = (props) => {
    return (
        <div className="gallery-wrapper">
            <>
                {props.children}
            </>
        </div>
    );
}

export default GalleryWrapper;
