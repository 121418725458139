import Use from "./Use.js";
import Accordion from "./Accordion.js";
import Images from "./Images";
import ArticleContent from "./ArticleContent";
import Arrow from "./Arrow";
import GalleryWrapper from "./GalleryWrapper";
import Gallery from "./Gallery";

const Rty = () => {
    return (
        <>
            <Use title="Rty" description="Permanentní make-up Veronika Teplice nabízí tetování rtů technikou Aquarelle, Fullshading a novinkou Nude Lips." /> 
            <article className="padding">
                <Images />
                <ArticleContent heading="Rty" />
                <Arrow url="#rty" text="Rty" />
            </article>
            <article id="rty" className="padding">
                <div className="article-content right padding" data-title="Důležité informace">
                    <h2>Rty</h2>
                    <ul>
                        <li className="accordion">
                            <span onClick={Accordion}>Co je mikropigmentace rtů?</span>
                            <ul className="panel">
                                <li>Je to procedura, při které se pečlivě zapravuje speciální pigment do oblasti rtů.</li>
                                <li>Vaše rtíky tak budou vypadat více výrazněji, šťavnatěji a svůdně i bez použití rtěnky.</li>
                                <li>Rtům můžeme pomocí barev dodat barevnější vzhled nebo jen vytvořit dojem zdravých, přírodně zabarvených rtů.</li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Jaké máme styly rtů?</span>
                            <ul className="panel">
                                <li>Rozlišujeme několik stylů tetování rtů podle toho, kam se pigment přesně umístí.</li>
                                <li>Provádím tyto tři styly:
                                    <ul>
                                        <li>Aquarelle
                                            <ul>
                                                <li>Jedná se o nejjemnější a nejžádanější styl tetování.</li>
                                                <li>Díky této technice vypadají rty velmi přirozeně s jemným nádechem použitého pigmentu, kterým se rty jen jemně popráší.</li>
                                                <li>Vytvoříme tak na puse efekt jemně zabarveného lesku.</li>
                                            </ul>
                                        </li>
                                        <li>Fullshading - celostínované
                                            <ul>
                                                <li>Tímto způsobem jsou celé rty rovnoměrně vybarvené s tím, že pigment je zapravován do rtu mnohem hustěji a pusa působí celkově sytým dojmem.</li>
                                            </ul>
                                        </li>
                                        <li>Nude Lips 
                                            <ul>
                                                <li>Je to autorská technika Veroniky Fleiš a v České republice i v EU může její logo používat jen pár stylistek.</li>
                                                <li>Jsem moc ráda, že Vám ji mohu nabídnout právě já.</li>
                                                <li>Tato technika je nejvhodnější na světlé až mdlé rty, přičemž se velmi speciálním postupem zabarvují do krásných přírodních odstínů, aby se tak podpořil jen jejich naturální vzhled.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Jak bude probíhat má návštěva?</span>
                            <ul className="panel">
                                <li>S klientkou nejdříve probereme, jaký styl je pro ni nejvhodnější.</li>
                                <li>Spolu pak vybereme pigment tak, aby se co nejlépe hodil k jejímu typu pleti.</li>
                                <li>Provedeme nákres, jemně si načrtnu konturu bez anestetik.</li>
                                <li>Poté nanesu anestetika a dostínuji do požadovaných odstínů celé rty.</li>
                                <li>Po ukončení aplikace rty ošetřím, klientce předám pokyny k péči v tištěné podobě a mastičku pro nejlepší hojení.</li>
                                <li>Celá procedura trvá přibližně 120 - 180 minut.</li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Co bych měla před zákrokem vědět?</span>
                            <ul className="panel">
                                <li>Na proceduru přijďte bez doprovodu. Tím je myšleno bez dětí, kamarádů, zvířátek, kočárků a dalších rodinných i nerodinných příslušníků. :-)</li>
                                <li>Pokud trpíte na opary nebo jste již v minulosti opar měla, je doporučeno užívat lék Herpesin 400, který začněte užívat nejméně 3 dny před procedurou.</li>
                                <li>Tablety Herpesin 400 jsou na lékařský předpis a nelze je nahradit mastičkou Herpesin.</li>
                                <li>Tři dny před zákrokem si denně udělejte peeling rtů.</li>
                                <li>Obstarejte si, prosím, brčko, které musíte používat než odpadnou stroupky (3 - 4 dny).</li>
                                <li>Na proceduru přijďte odpočatá, najezená a napitá.</li>
                                <li>V den procedury nepijte alkohol, kávu a neberte analgetika.</li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Pro koho není aplikace vhodná?</span>
                            <ul className="panel">
                                <li>Pro klientky, které se nevyhnou dennímu nošení roušek.</li>
                                <li>Pokud trpíte jakoukoliv z níže popsaných kontraindikací, není možné zákrok provést:
                                    <ul>
                                        <li>cukrovka s aplikací inzulínu</li>
                                        <li>kardiostimulátor</li>
                                        <li>srdeční choroby</li>
                                        <li>cévní choroby</li>
                                        <li>snížená srážlivost krve</li>
                                        <li>léky na ředění krve</li>
                                        <li>snížená imunita</li>
                                        <li>užívání antibiotik</li>
                                        <li>horečnaté stavy</li>
                                        <li>akutní onemocnění či nachlazení</li>
                                        <li>kožní nemoc - dermatitida, vyrážka (včetně akutní alergické) apod.</li>
                                        <li>rakovina v aktivním stádiu či rakovina kůže v jakékoliv fázi</li>
                                        <li>epilepsie</li>
                                        <li>HIV či jiná přenosná nemoc</li>
                                        <li>alergie na kosmetické látky, alergie na anestetika, alergie na prvotně aplikovaný pigment</li>
                                        <li>těhotenství a kojení</li>
                                        <li>příliš kapilární pleť</li>
                                        <li>keloidní či hypertrofické jizvy</li>
                                        <li>psychické onemocnění či celková psychická nepohoda či úzkost</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Bolí to?</span>
                            <ul className="panel">
                                <li>Tetování rtů se považuje za bolestivé, ale není tomu tak.</li>
                                <li>V případě nepohodlí jsou použita anestetika.</li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Jak pečovat o rty?</span>
                            <ul className="panel">
                                <li>Ať už se rozhodnete pro jakoukoliv proceduru, při které je porušována integrita kůže, tak berte, prosím, na vědomí, že se k ní vážou různá pravidla a omezení. Nedodržením pokynů můžete narušit proces hojení a negativně tak ovlivnit výsledný efekt.</li>
                                <li>14 dní po proceduře NENAVŠTĚVUJTE sauny, solária, bazény a koupaliště, přímé slunce, fitness centra nebo jiná sportoviště.</li>
                                <li>Podrobné pokyny k péči o ošetřené místo ode mne obdržíte v písemné podobě.</li>
                            </ul>
                        </li>
                        <li className="accordion has-pricelist">
                            <span onClick={Accordion}>Kolik to stojí?</span>
                            <ul className="panel">
                                <li className="pricelist">
                                    <ul className="pricelist-inner">
                                        <li className="pricelist-row">
                                            <span>Aquarelle</span>
                                            <span><strong>5000 Kč + korekce 1000 Kč + luxusní balíček s péčí o rty ZDARMA</strong></span>
                                        </li>
                                        <li className="pricelist-row">
                                            <span>Fullshading</span>
                                            <span><strong>5000 Kč + korekce 1000 Kč + luxusní balíček s péčí o rty ZDARMA</strong></span>
                                        </li>
                                        <li className="pricelist-row">
                                            <span>Nude Lips</span>
                                            <span><strong>5500 Kč + korekce 1000 Kč + luxusní balíček s péčí o rty ZDARMA</strong></span>
                                        </li>
                                        <li className="pricelist-row">
                                            <span>Oživení do 2 let</span>
                                            <span><strong>3500 Kč + korekce 1000 Kč + mastička zdarma</strong>&nbsp;(platí pouze pro mé práce)</span>
                                        </li>
                                    </ul>
                                </li>
                                <li>Cena zahrnuje
                                    <ul>
                                        <li>Konzultaci o průběhu procedury, kde se můžete zeptat na vše, co Vás zajímá.</li>
                                        <li>Poté zhodnocení a vyměření tvaru tak, aby co nejvíce podtrhoval Vaši krásu.</li>
                                        <li>Dále výběr toho nejvhodnějšího pigmentu pro co nejpřirozenější vzhled a samozřejmě v té nejvyšší kvalitě.</li>
                                        <li>Samotnou proceduru, kde se budu snažit, abyste se cítila co nejvíce pohodlně.</li>
                                        <li>Také předání informací ohledně následné péče.</li>
                                        <li>A jako bonus luxusní balíček se všemi produkty, které Vám pomohou k získání toho nejlepšího vyhojeného výsledku.</li>
                                        <li>Samozřejmostí jsou detailní pokyny k péči v tištěné podobě.</li>
                                        <li>A v neposlední řadě následné poradenství v případě dotazů.</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <br /><br />
                    <h3>Nepřehlédněte!</h3>
                    <ul>
                        <li>Mé milé klientky, při objednání si s Vámi domlouvám zároveň termín korekce a to hlavně proto, abyste na ni přišly v tu nejvhodnější dobu a také s ohledem na obsazenost termínů. Pokud budete chtít termín změnit, tak prosím počítejte s tím, že další volný termín může být za delší dobu a rty už budou potřebovat více úprav. Zbytečně tím můžete snižovat kvalitu vyhojených výsledků. Proto Vás vždy prosím, abyste si daný termín řádně promyslely, abychom předešly zbytečným komplikacím.</li>
                        <li>Pokud budete přeci jen potřebovat změnit termín, učiňte tak nejdéle 24 hodin před původním termínem korekce. V opačném případě Vám bude ke korekci účtována navíc částka 500 Kč. Není to nic osobního, jen jsem v tu chvíli na Vaši korekci potřebovala dvojnásobek již započteného času. Pevně věřím, že mi rozumíte.</li>
                        <li>Cena korekce platí pouze pro korekce uskutečněné do 3 měsíců od první aplikace. Pokud budete korekci potřebovat později, cena může být navýšena.</li>
                        <li>Vyhrazuji si právo na výběr zálohy za objednanou proceduru, která je nevratná, pokud změnu termínu neohlásíte nejpozději 48 hodin předem.</li>
                        <li>Platba pouze v hotovosti!</li>
                    </ul>
                </div>
            </article>
            <GalleryWrapper>
                <Gallery lower="rty" text="Rty" count="103" />
            </GalleryWrapper>       
        </>
    );
}

export default Rty;
