import { useState } from "react";
import ReactImageVideoLightbox from "react-image-video-lightbox";

const Img = ({ category, number, alt }) => {
    const [open, setOpen] = useState(false);
	const openLightbox = () => {
		setOpen(true);
	};	

    return (
        <>   
            <li className="has-img">
                <span className="gallery-link" onClick={() => openLightbox()}><img src={require(`../themes/frontend/img/poradna/${category}/${category}-${number}.webp`)} alt={alt} width="512" height="512" /></span>
                {open && (
                    <ReactImageVideoLightbox
                        data={[
                            {
                                url: require(`../themes/frontend/img/poradna/${category}/${category}-${number}.webp`),
                                type: "photo",
                                altTag: {alt},
                            }
                        ]}
                        startIndex={0}
                        onCloseCallback={() => setOpen(false)}
                    />
                )}
            </li>
        </>
    )
        
}

export default Img;
