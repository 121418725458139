import { useLocation, Link } from "react-router-dom";
import { WhatsAppWidget } from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";
import Logo from "../themes/frontend/img/logo.svg";
import LogoWhite from "../themes/frontend/img/logo-white.svg";
import MenuLang from "./MenuLang";

const Header = () => {
    const onClick = () => {
        document.getElementsByTagName("body")[0].classList.toggle("menu-open");
    }

    const location = useLocation();

    return (
        <header itemScope="" itemType="https://schema.org/WPHeader">
            <nav itemScope="" itemType="https://schema.org/SiteNavigationElement">
                <span className="menu-open" onClick={onClick}>
                    <span className="dashes">
                        <span className="dash"></span>
                        <span className="dash"></span>
                        <span className="dash"></span>
                        <span className="dash"></span>
                        <span className="dash"></span>
                    </span>
                </span>
                <h1 className="logo">
                    <Link to="/" title="Permanentní make-up Veronika">
                        <img className="logo-black" src={Logo} alt="Permanentní make-up Veronika" width="231" height="150" />
                        <img className="logo-white" src={LogoWhite} alt="Permanentní make-up Veronika" width="231" height="150" />
                    </Link>
                    <span className="sr-only">Permanentní make-up Veronika</span>
                </h1>
                <div className="buttons">
                    <div id="instagramButton">
                        <a href="https://instagram.com/permanentnimakeupveronika" title="Instagram Permanentní make-up Veronika" target="_blank" rel="noreferrer">Instagram Permanentní make-up Veronika</a>
                    </div>
                    <div id="facebookButton">
                        <a href="https://facebook.com/100035014675032" title="Facebook Permanentní make-up Veronika" target="_blank" rel="noreferrer">Facebook Permanentní make-up Veronika</a>
                    </div>
                    <div id="WAButton">
                        <WhatsAppWidget phoneNumber="+420728313090" companyName="Veronika" replyTimeText="Od pondělí do pátku, 9:00 - 17:00" message="Dobrý den, jak Vám mohu pomoci?" inputPlaceHolder="Napište zprávu" sendButtonText="Odeslat" />
                    </div>
                    <div id="phoneButton">
                        <a href="tel:+420728313090" title="+420 728 313 090">+420 728 313 090</a>
                    </div> 
                </div>
                <div className="menu-wrapper">
                    <div className="menu">
                        <div className="menu-main">
                            <ul>     
                                <li className={location.pathname === "/" ? "active" : ""}><Link to="/" title="Úvod" onClick={onClick}>Úvod</Link></li>                   
                                <li className={location.pathname === "/o-mne" ? "active" : ""}><Link to="/o-mne" title="O mně" onClick={onClick}>O mně</Link></li>
                                <li className={location.pathname === "/poradna" ? "active" : ""}><Link to="/poradna" title="Poradna" onClick={onClick}>Poradna</Link></li>
                                <li className={location.pathname === "/pudrove-oboci" ? "active" : ""}><Link to="/pudrove-oboci" title="Pudrové obočí" onClick={onClick}>Pudrové obočí</Link></li>
                                <li className={location.pathname === "/rty" ? "active" : ""}><Link to="/rty" title="Rty" onClick={onClick}>Rty</Link></li>
                                <li className={location.pathname === "/ocni-linky" ? "active" : ""}><Link to="/ocni-linky" title="Oční linky" onClick={onClick}>Oční linky</Link></li>
                                <li className={location.pathname === "/mini-aquarelle-tattoo" ? "active" : ""}><Link to="/mini-aquarelle-tattoo" title="Mini Aquarelle Tattoo" onClick={onClick}>Mini Aquarelle Tattoo</Link></li>
                                <li className={location.pathname === "/odstraneni-pmu" ? "active" : ""}><Link to="/odstraneni-pmu" title="Odstranění PMU" onClick={onClick}>Odstranění PMU</Link></li>
                                <li className={location.pathname === "/nastrelovani-nausnic" ? "active" : ""}><Link to="/nastrelovani-nausnic" title="Nastřelování náušnic" onClick={onClick}>Nastřelování náušnic</Link></li>
                                <li className={location.pathname === "/cenik" ? "active" : ""}><Link to="/cenik" title="Ceník" onClick={onClick}>Ceník</Link></li>
                                <li className={location.pathname === "/darkove-poukazy" ? "active" : ""}><Link to="/darkove-poukazy" title="Dárkové poukazy" onClick={onClick}>Dárkové poukazy</Link></li>  
                                {false ? 
                                    <li className={location.pathname === "/obchodni-podminky" ? "active" : ""}><Link to="/obchodni-podminky" title="Obchodní podmínky" onClick={onClick}>Obchodní podmínky</Link></li>
                                : ""}    
                                <li className={location.pathname === "/kontakt" ? "active" : ""}><Link to="/kontakt" title="Kontakt" onClick={onClick}>Kontakt</Link></li>  
                            </ul>
                        </div>
                        <MenuLang />
                        <div className="designer">
                            <p>&copy; 2017 - {new Date().getFullYear()} Permanentní make-up Veronika</p>
                            <p>Vytvořila Jedničková Digitální agentura <a href="https://www.core1.agency" title="Jedničková Digitální agentura core1" target="_blank" rel="noreferrer">Jedničková Digitální agentura core1</a></p>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;
