const ArticleContent = ({ heading }) => {
    return (
        <div className="article-content">
            <h2>{heading}</h2>
            <h3>Permanent makeup</h3>
        </div>
    );
}

export default ArticleContent;
