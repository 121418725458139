import Use from "./Use.js";
import Images from "./Images";
import ArticleContent from "./ArticleContent";
import Arrow from "./Arrow";

const Kontakt = () => {
    return (
        <>
            <Use title="Kontakt" description="Veronika Zelenková jako Permanentní make-up Veronika Teplice se sídlem ve Studio Pano, Masarykova tř. 2475/57, 415 01 Teplice, Ústecký kraj." /> 
            <article className="padding">
                <Images />
                <ArticleContent heading="Kontakt" />
                <Arrow url="#kontakt" text="Kontakt" />
            </article>
            <article id="kontakt" className="padding">
                <div className="article-content left padding" data-title="Důležité informace">
                    <h2>Kontakt</h2>
                    <ul>
                        <li>
                            <address>
                                Veronika Zelenková<br />
                                Studio Pano<br />
                                Masarykova tř. 2475/57<br />
                                415 01 <strong>Teplice</strong>
                            </address>
                        </li>
                        <li>
                            IČ: 86742701<br />
                            Fyzická osoba podnikající dle živnostenského zákona
                        </li>
                        <li>
                            <a href="https://www.google.com/maps/place/Permanentn%C3%AD+make-up+Veronika/@50.6475556,13.8329472,18z/data=!4m5!3m4!1s0x47098fcf9e4cf0af:0xa2efe6bff2d17392!8m2!3d50.6474931!4d13.8341861" title="Otevřít v Google mapách" target="_blank" rel="noreferrer">Otevřít v Google mapách</a>
                        </li>
                        <li>
                            Telefon: <a href="tel:+420728313090" title="728 313 090">728 313 090</a><br />
                            Pro objednání volejte od pondělí do pátku, 9:00 - 17:00
                        </li>
                    </ul>
                </div>
            </article>
        </>
    );
}

export default Kontakt;
