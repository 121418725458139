import Use from "./Use.js";
import Accordion from "./Accordion.js";
import Images from "./Images";
import ArticleContent from "./ArticleContent";
import Arrow from "./Arrow";

const Cenik = () => {   
    return (
        <>
            <Use title="Ceník" description="V ceníku najdete ceny například za permanentní obočí, korekce nebo aktuální slevy a akce." /> 
            <article className="padding">
                <Images />
                <ArticleContent heading="Ceník" />
                <Arrow url="#cenik" text="Ceník" />
            </article>
            <article id="cenik" className="padding">
                <div className="article-content left padding" data-title="Důležité informace">
                    <h2>Ceník platný <span className="no-wrap">od 1. 4. 2023</span></h2>
                    <ul>
                        <li className="accordion has-pricelist">
                            <span onClick={Accordion}>Pudrové obočí</span>
                            <ul className="panel">
                                <li className="pricelist">
                                    <ul className="pricelist-inner">
                                        <li className="pricelist-row">
                                            <span>Pudrové obočí</span>
                                            <span><strong>4500 Kč + korekce 1000 Kč + luxusní balíček s péčí o obočí ZDARMA</strong></span>
                                        </li>
                                        <li className="pricelist-row">
                                            <span>Oživení do 2 let</span>
                                            <span><strong>3000 Kč + korekce 1000 Kč + mastička ZDARMA</strong>&nbsp;(platí pouze pro mé práce)</span>
                                        </li>
                                    </ul>
                                </li>
                                <li>Cena zahrnuje
                                    <ul>
                                        <li>Konzultaci o průběhu procedury, kde se můžete zeptat na vše, co Vás zajímá.</li>
                                        <li>Poté zhodnocení a vyměření tvaru tak, aby co nejvíce podtrhoval Vaši krásu.</li>
                                        <li>Dále výběr toho nejvhodnějšího pigmentu pro co nejpřirozenější vzhled a samozřejmě v té nejvyšší kvalitě.</li>
                                        <li>Samotnou proceduru, kde se budu snažit, abyste se cítila co nejvíce pohodlně.</li>
                                        <li>Také předání informací ohledně následné péče.</li>
                                        <li>A jako bonus luxusní balíček se všemi produkty, které Vám pomohou k získání toho nejlepšího vyhojeného výsledku.</li>
                                        <li>Samozřejmostí jsou detailní pokyny k péči v tištěné podobě.</li>
                                        <li>A v neposlední řadě následné poradenství v případě dotazů.</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="accordion has-pricelist">
                            <span onClick={Accordion}>Rty</span>
                            <ul className="panel">
                                <li className="pricelist">
                                    <ul className="pricelist-inner">
                                        <li className="pricelist-row">
                                            <span>Aquarelle</span>
                                            <span><strong>5000 Kč + korekce 1000 Kč + luxusní balíček s péčí o rty ZDARMA</strong></span>
                                        </li>
                                        <li className="pricelist-row">
                                            <span>Fullshading</span>
                                            <span><strong>5000 Kč + korekce 1000 Kč + luxusní balíček s péčí o rty ZDARMA</strong></span>
                                        </li>
                                        <li className="pricelist-row">
                                            <span>Nude Lips</span>
                                            <span><strong>5500 Kč + korekce 1000 Kč + luxusní balíček s péčí o rty ZDARMA</strong></span>
                                        </li>
                                        <li className="pricelist-row">
                                            <span>Oživení do 2 let</span>
                                            <span><strong>3500 Kč + korekce 1000 Kč + mastička zdarma</strong>&nbsp;(platí pouze pro mé práce)</span>
                                        </li>
                                    </ul>
                                </li>
                                <li>Cena zahrnuje
                                    <ul>
                                        <li>Konzultaci o průběhu procedury, kde se můžete zeptat na vše, co Vás zajímá.</li>
                                        <li>Poté zhodnocení a vyměření tvaru tak, aby co nejvíce podtrhoval Vaši krásu.</li>
                                        <li>Dále výběr toho nejvhodnějšího pigmentu pro co nejpřirozenější vzhled a samozřejmě v té nejvyšší kvalitě.</li>
                                        <li>Samotnou proceduru, kde se budu snažit, abyste se cítila co nejvíce pohodlně.</li>
                                        <li>Také předání informací ohledně následné péče.</li>
                                        <li>A jako bonus luxusní balíček se všemi produkty, které Vám pomohou k získání toho nejlepšího vyhojeného výsledku.</li>
                                        <li>Samozřejmostí jsou detailní pokyny k péči v tištěné podobě.</li>
                                        <li>A v neposlední řadě následné poradenství v případě dotazů.</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="accordion has-pricelist">
                            <span onClick={Accordion}>Oční linky</span>
                            <ul className="panel">
                                <li className="pricelist">
                                    <ul className="pricelist-inner">
                                        <li className="pricelist-row">
                                            <span>Natural - pouze v řasové linii</span>
                                            <span><strong>3500 Kč + korekce 1000 Kč</strong></span>
                                        </li>
                                        <li className="pricelist-row">
                                            <span>Shading - stínované</span>
                                            <span><strong>4500 Kč + korekce 1000 Kč</strong></span>
                                        </li>
                                    </ul>
                                </li>
                                <li>Cena zahrnuje
                                    <ul>
                                        <li>Konzultaci o průběhu procedury, kde se můžete zeptat na vše, co Vás zajímá.</li>
                                        <li>V případě stínovaných linek zhodnocení a vyměření tvaru tak, aby co nejvíce podtrhoval Vaši krásu.</li>
                                        <li>Dále výběr toho nejvhodnějšího pigmentu pro co nejpřirozenější vzhled a samozřejmě v té nejvyšší kvalitě.</li>
                                        <li>Samotnou proceduru, kde se budu snažit, abyste se cítila co nejvíce pohodlně.</li>
                                        <li>Také předání informací ohledně následné péče.</li>
                                        <li>Samozřejmostí jsou detailní pokyny k péči v tištěné podobě.</li>
                                        <li>A v neposlední řadě následné poradenství v případě dotazů.</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="accordion has-pricelist">
                            <span onClick={Accordion}>Mini Aquarelle Tattoo</span>
                            <ul className="panel">
                                <li className="pricelist">
                                    <ul className="pricelist-inner">
                                        <li>Cena i doba tetování se odvíjí od náročnosti a velikosti předlohy.</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="accordion has-pricelist">
                            <span onClick={Accordion}>Odstranění PMU</span>
                            <ul className="panel">
                                <li className="pricelist">
                                    <ul className="pricelist-inner">
                                        <li className="pricelist-row">
                                            <span>1 sezení</span>
                                            <span><strong>1000 Kč + mastička zdarma</strong></span>
                                        </li>
                                        <li>Pro klientky, které mají vytetovanou partii k odstranění pouze ode mne platí 50% sleva.</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="accordion has-pricelist">
                            <span onClick={Accordion}>Nastřelování náušnic</span>
                            <ul className="panel">
                                <li className="pricelist">
                                    <ul className="pricelist-inner">
                                        <li className="pricelist-row">
                                            <span>1x Náušnice CHIRURGICKÁ OCEL (ANTIALERGENNÍ)<br />BEZ KAMÍNKU nebo S KAMÍNKEM SWAROVSKI</span>
                                            <span><strong>500 Kč</strong></span>
                                        </li>
                                        <li className="pricelist-row">
                                            <span>1x Náušnice CHIRURGICKÁ OCEL POZLACENÁ 24KT ZLATEM<br />BEZ KAMÍNKU nebo S KAMÍNKEM SWAROVSKI</span>
                                            <span><strong>500 Kč</strong></span>
                                        </li>                                     
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <br /><br />
                    <h3>Nepřehlédněte!</h3>
                    <ul>
                        <li>Mé milé klientky, při objednání si s Vámi domlouvám zároveň termín korekce a to hlavně proto, abyste na ni přišla v tu nejvhodnější dobu a také s ohledem na obsazenost termínů. Pokud budete chtít termín změnit, tak prosím počítejte s tím, že další volný termín může být za delší dobu a obočí už bude potřebovat více úprav. Zbytečeně tím můžetě snižovat kvalitu vyhojených výsledků. Proto Vás vždy prosím, abyste si daný termín řádně promyslely, abychom předešly zbytečným komplikacím.</li>
                        <li>Pokud budete přeci jen potřebovat změnit termín, učiňte tak nejdéle 24 hodin před původním termínem korekce. V opačném případě Vám bude ke korekci účtována navíc částka 500 Kč. Není to nic osobního, jen jsem v tu chvíli na Vaši korekci potřebovala dvojnásobek již započteného času. Pevně věřím, že mi rozumíte.</li>
                        <li>Cena korekce platí pouze pro korekce uskutečněné do 3 měsíců od první aplikace. Pokud budete korekci potřebovat později, cena může být navýšena.</li>
                        <li>Vyhrazuji si právo na výběr zálohy za objednanou proceduru, která je nevratná, pokud změnu termínu neohlásíte nejpozději 48 hodin předem.</li>
                        <li>Platba pouze v hotovosti!</li>
                    </ul>
                </div>
            </article>
        </>
    );
}

export default Cenik;
