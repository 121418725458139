import Use from "./Use.js";
import Accordion from "./Accordion.js";
import Images from "./Images";
import ArticleContent from "./ArticleContent";
import Arrow from "./Arrow";
import GalleryWrapper from "./GalleryWrapper";
import Gallery from "./Gallery";

const MiniAquarelleTattoo = () => {
    return (
        <>
            <Use title="Mini Aquarelle Tattoo" description="Permanentní make-up Veronika Teplice nabízí techniku tělového barevného tetování Mini Aquarelle Tattoo." /> 
            <article className="padding">
                <Images />
                <ArticleContent heading="Mini Aquarelle Tattoo" />
                <Arrow url="#mini-aquarelle-tattoo" text="Mini Aquarelle Tattoo" />
            </article>
            <article id="mini-aquarelle-tattoo" className="padding">
                <div className="article-content right padding" data-title="Důležité informace">
                    <h2>Mini Aquarelle Tattoo</h2>
                    <ul>
                        <li className="accordion">
                            <span onClick={Accordion}>Co je Mini Aquarelle Tattoo?</span>
                            <ul className="panel">
                                <li>Je to krásná metoda tetování pocházející z Ameriky.</li>
                                <li>Vyznačuje se černými liniemi, které doplňují výrazné a živé barvy připomínající akvarelové barvy.</li>
                                <li>Ve světě ale i u nás se těší čím dál tím větší oblibě.</li>
                                <li>Je to hravé, zábavné, ale i umělecké tetování.</li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Pro koho není aplikace vhodná?</span>
                            <ul className="panel">
                                <li>Pokud trpíte jakoukoliv z níže popsaných kontraindikací, není možné zákrok provést:
                                    <ul>
                                        <li>cukrovka s aplikací inzulínu</li>
                                        <li>kardiostimulátor</li>
                                        <li>srdeční choroby</li>
                                        <li>cévní choroby</li>
                                        <li>snížená srážlivost krve</li>
                                        <li>léky na ředění krve</li>
                                        <li>snížená imunita</li>
                                        <li>užívání antibiotik</li>
                                        <li>horečnaté stavy</li>
                                        <li>akutní onemocnění či nachlazení</li>
                                        <li>solární erytém (spálení)</li>
                                        <li>kožní nemoc - dermatitida, vyrážka (včetně akutní alergické) apod.</li>
                                        <li>rakovina v aktivním stádiu či rakovina kůže v jakékoliv fázi</li>
                                        <li>epilepsie</li>
                                        <li>HIV či jiná přenosná nemoc</li>
                                        <li>alergie na kosmetické látky, alergie na anestetika, alergie na prvotně aplikovaný pigment</li>
                                        <li>těhotenství a kojení</li>
                                        <li>příliš kapilární pleť</li>
                                        <li>keloidní či hypertrofické jizvy</li>
                                        <li>psychické onemocnění či celková psychická nepohoda či úzkost</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Jak pečovat o své tetování?</span>
                            <ul className="panel">
                                <li>Ať už se rozhodnete pro jakoukoliv proceduru, při které je porušována integrita kůže, tak berte, prosím, na vědomí, že se k ní vážou různá pravidla a omezení. Nedodržením pokynů můžete narušit proces hojení a negativně tak ovlivnit výsledný efekt.</li>
                                <li>Podrobné pokyny k péči o ošetřené místo ode mne obdržíte v písemné podobě.</li>
                            </ul>
                        </li>
                        <li className="accordion has-pricelist">
                            <span onClick={Accordion}>Kolik to stojí?</span>
                            <ul className="panel">
                                <li className="pricelist">
                                    <ul className="pricelist-inner">
                                        <li>Cena i doba tetování se odvíjí od náročnosti a velikosti předlohy.</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <br /><br />
                    <h3>Nepřehlédněte!</h3>
                    <ul>
                        <li>Platba pouze v hotovosti!</li>
                    </ul>
                </div>
            </article>
            <GalleryWrapper>
                <Gallery lower="mini-aquarelle-tattoo" text="Mini Aquarelle Tattoo" count="11" /> 
            </GalleryWrapper>  
        </>
    );
}

export default MiniAquarelleTattoo;
