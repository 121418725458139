import GoogleMapReact from "google-map-react";

const AnyReactComponent = () =>
    <div className="map-infobox">
        <div className="infobox-content">
            <span className="infobox-name">Permanentní make-up Veronika</span>
            <span className="infobox-text">Masarykova tř. 2475/57, 415 01 Teplice</span>
            <div className="infobox-tools">
                <a className="infobox-directions" href="https://www.google.com/maps/dir//Permanentn%C3%AD+make-up+Veronika,+Masarykova+t%C5%99.+2475%2F57,+415+01+Teplice/@50.6475573,13.8318528,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47098fcf9e4cf0af:0xa2efe6bff2d17392!2m2!1d13.8341861!2d50.6474931" target="_blank" rel="noreferrer">Trasa</a>
            </div>
        </div>
    </div>
 
const Footer = () => {
    return (
        <>
            <footer id="footer" itemScope="" itemType="https://schema.org/WPFooter">
                <div className="article-content right" data-title="Najít cestu">
                    <div className="google-map">
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "AIzaSyBEgdX22d4uCOdZM9-mhS8Vfd1kdW-obU8" }}
                            defaultCenter={{lat:50.6474931, lng: 13.8341861}}
                            defaultZoom={15}
                            options={{
                                disableDefaultUI: true,
                                styles: [{"featureType":"all","elementType":"geometry.fill","stylers":[{"weight":"2.00"}]},{"featureType":"all","elementType":"geometry.stroke","stylers":[{"color":"#9c9c9c"}]},{"featureType":"all","elementType":"labels.text","stylers":[{"visibility":"on"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"landscape","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"landscape.man_made","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road","elementType":"geometry.fill","stylers":[{"color":"#eeeeee"}]},{"featureType":"road","elementType":"labels.text.fill","stylers":[{"color":"#7b7b7b"}]},{"featureType":"road","elementType":"labels.text.stroke","stylers":[{"color":"#ffffff"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#46bcec"},{"visibility":"on"}]},{"featureType":"water","elementType":"geometry.fill","stylers":[{"color":"#c8d7d4"}]},{"featureType":"water","elementType":"labels.text.fill","stylers":[{"color":"#070707"}]},{"featureType":"water","elementType":"labels.text.stroke","stylers":[{"color":"#ffffff"}]}]
                            }}
                        >
                            <AnyReactComponent 
                                lat={50.6474931}
                                lng={13.8341861}
                            />
                        </GoogleMapReact>
                    </div>
                </div>   
            </footer>
        </>
    );
}

export default Footer;
