import Use from "./Use.js";
import Accordion from "./Accordion.js";
import Images from "./Images";
import ArticleContent from "./ArticleContent";
import Arrow from "./Arrow";
import GalleryWrapper from "./GalleryWrapper";
import Gallery from "./Gallery";

const PudroveOboci = () => {
    return (
        <>
            <Use title="Pudrové obočí" description="Permanentní make-up Veronika Teplice nabízí tetování obočí technikou pudrování." /> 
            <article className="padding">
                <Images />
                <ArticleContent heading="Pudrové obočí" />
                <Arrow url="#pudrove-oboci" text="Pudrové obočí" />
            </article>
            <article id="pudrove-oboci" className="padding">
                <div className="article-content right padding" data-title="Důležité informace">
                    <h2>Pudrové obočí</h2>
                    <ul>
                        <li className="accordion">
                            <span onClick={Accordion}>Co je pudrové obočí?</span>
                            <ul className="panel">
                                <li>Je to inovativní metoda mikropigmentace kůže v oblasti nadočnicových oblouků</li>
                                <li>Díky této technice vypadá obočí krásně přirozeně.</li>
                                <li>Obočí se pomocí jehly a speciálních pigmentů jen lehce definuje a co nejpřirozeněji se vystínuje tak, aby co nejlépe odpovídal reálnému obočí.</li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Jak bude probíhat má návštěva?</span>
                            <ul className="panel">
                                <li>Vyměřuji tvar tak, aby co nejvíce ladil s Vaším obličejem.</li>
                                <li>Pamatujte na to, že obočí by Vás mělo rozjasnit a omladit.</li>
                                <li>Poté zvolíme pigment hodící se k Vašemu typu pleti.</li>
                                <li>Po ukončení aplikace místo ošetřím a klientce předám pokyny k péči v tištěné podobě.</li>
                                <li>Celá procedura trvá přibližně 90 - 120 minut.</li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Co bych měla před zákrokem vědět?</span>
                            <ul className="panel">
                                <li>Na proceduru přijďte bez doprovodu. Tím je myšleno bez dětí, kamarádů, zvířátek, kočárků a dalších rodinných i nerodinných příslušníků. :-)</li>
                                <li>Tetování obočí má, co se týká tvaru a barvy, svá pravidla, která při své práci vždy dodržuji. Pokud nejste ochotná tato pravidla respektovat, nebudu Vám moci vyhovět. Tím je myšleno, že určitě nebudu dělat extravagantní tvary nebo na obočí používat černou barvu.</li>
                                <li>Naším cílem by mělo být dosáhnout co nejpřirozenějšího vzhledu.</li>
                                <li>Pokud již máte vytetované nějaké obočí a je jedno, jak je staré a jak je viditelné a není dělané mnou, tak to při objednávání musíte předem nahlásit.</li>
                                <li>Tetování obočí je proces, ne jednorázová akce. Takže pokud není obočí vykorektované, tak není dokončené a dokonalé. Tudíž, pokud máte nějakou událost, na kterou chcete mít hotové obočí, počítejte i s časem vyhojení a korekcí.</li>
                                <li>Na proceduru přijďte odpočatá, najezená a napitá.</li>
                                <li>V den procedury nepijte alkohol, kávu a neberte analgetika.</li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Pro koho není aplikace vhodná?</span>
                            <ul className="panel">
                                <li>Pokud trpíte jakoukoliv z níže popsaných kontraindikací, není možné zákrok provést:
                                    <ul>
                                        <li>cukrovka s aplikací inzulínu</li>
                                        <li>kardiostimulátor</li>
                                        <li>srdeční choroby</li>
                                        <li>cévní choroby</li>
                                        <li>snížená srážlivost krve</li>
                                        <li>léky na ředění krve</li>
                                        <li>snížená imunita</li>
                                        <li>užívání antibiotik</li>
                                        <li>horečnaté stavy</li>
                                        <li>akutní onemocnění či nachlazení</li>
                                        <li>solární erytém (spálení)</li>
                                        <li>kožní nemoc - dermatitida, vyrážka (včetně akutní alergické) apod.</li>
                                        <li>rakovina v aktivním stádiu či rakovina kůže v jakékoliv fázi</li>
                                        <li>epilepsie</li>
                                        <li>HIV či jiná přenosná nemoc</li>
                                        <li>alergie na kosmetické látky, alergie na anestetika, alergie na prvotně aplikovaný pigment</li>
                                        <li>těhotenství a kojení</li>
                                        <li>příliš kapilární pleť</li>
                                        <li>keloidní či hypertrofické jizvy</li>
                                        <li>psychické onemocnění či celková psychická nepohoda či úzkost</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Bolí to?</span>
                            <ul className="panel">
                                <li>Mikropigmentace obočí není bolestivou procedurou.</li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Jak pečovat o obočí?</span>
                            <ul className="panel">
                                <li>Ať už se rozhodnete pro jakoukoliv proceduru, při které je porušována integrita kůže, tak berte, prosím, na vědomí, že se k ní vážou různá pravidla a omezení. Nedodržením pokynů můžete narušit proces hojení a negativně tak ovlivnit výsledný efekt.</li>
                                <li>14 dní po proceduře NENAVŠTĚVUJTE sauny, solária, bazény a koupaliště, přímé slunce, fitness centra nebo jiná sportoviště. Na slunci můžeme použít klobouk nebo kšiltovku. Sluneční brýle NE, ty jako ochrana NESTAČÍ!</li>
                                <li>Během vyhojování se barva obočí může malinko měnit. NEPANIKAŘÍME! To je naprosto běžné. Při korekci se vše doladí. Od toho korekce jsou.</li>
                                <li>Podrobné pokyny k péči o ošetřené místo ode mne obdržíte v písemné podobě.</li>
                            </ul>
                        </li>
                        <li className="accordion has-pricelist">
                            <span onClick={Accordion}>Kolik to stojí?</span>
                            <ul className="panel">
                                <li className="pricelist">
                                    <ul className="pricelist-inner">
                                        <li className="pricelist-row">
                                            <span>Pudrové obočí</span>
                                            <span><strong>4500 Kč + korekce 1000 Kč + luxusní balíček s péčí o obočí ZDARMA</strong></span>
                                        </li>
                                        <li className="pricelist-row">
                                            <span>Oživení do 2 let</span>
                                            <span><strong>3000 Kč + korekce 1000 Kč + mastička ZDARMA</strong>&nbsp;(platí pouze pro mé práce)</span>
                                        </li>
                                    </ul>
                                </li>
                                <li>Cena zahrnuje
                                    <ul>
                                        <li>Konzultaci o průběhu procedury, kde se můžete zeptat na vše, co Vás zajímá.</li>
                                        <li>Poté zhodnocení a vyměření tvaru tak, aby co nejvíce podtrhoval Vaši krásu.</li>
                                        <li>Dále výběr toho nejvhodnějšího pigmentu pro co nejpřirozenější vzhled a samozřejmě v té nejvyšší kvalitě.</li>
                                        <li>Samotnou proceduru, kde se budu snažit, abyste se cítila co nejvíce pohodlně.</li>
                                        <li>Také předání informací ohledně následné péče.</li>
                                        <li>A jako bonus luxusní balíček se všemi produkty, které Vám pomohou k získání toho nejlepšího vyhojeného výsledku.</li>
                                        <li>Samozřejmostí jsou detailní pokyny k péči v tištěné podobě.</li>
                                        <li>A v neposlední řadě následné poradenství v případě dotazů.</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <br /><br />
                    <h3>Nepřehlédněte!</h3>
                    <ul>
                        <li>Mé milé klientky, při objednání si s Vámi domlouvám zároveň termín korekce a to hlavně proto, abyste na ni přišly v tu nejvhodnější dobu a také s ohledem na obsazenost termínů. Pokud budete chtít termín změnit, tak prosím počítejte s tím, že další volný termín může být za delší dobu a obočí už bude potřebovat více úprav. Zbytečně tím můžete snižovat kvalitu vyhojených výsledků. Proto Vás vždy prosím, abyste si daný termín řádně promyslely, abychom předešly zbytečným komplikacím.</li>
                        <li>Pokud budete přeci jen potřebovat změnit termín, učiňte tak nejdéle 24 hodin před původním termínem korekce. V opačném případě Vám bude ke korekci účtována navíc částka 500 Kč. Není to nic osobního, jen jsem v tu chvíli na Vaši korekci potřebovala dvojnásobek již započteného času. Pevně věřím, že mi rozumíte.</li>
                        <li>Cena korekce platí pouze pro korekce uskutečněné do 3 měsíců od první aplikace. Pokud budete korekci potřebovat později, cena může být navýšena.</li>
                        <li>Vyhrazuji si právo na výběr zálohy za objednanou proceduru, která je nevratná, pokud změnu termínu neohlásíte nejpozději 48 hodin předem.</li>
                        <li>Platba pouze v hotovosti!</li>
                    </ul>
                </div>
            </article>
            <GalleryWrapper>
                <Gallery lower="pudrove-oboci" text="Pudrové obočí" count="32" />  
            </GalleryWrapper>     
        </>
    );
}

export default PudroveOboci;
