const ScrollToTop = () => {
    const onScrollToTop = (e) => {
        e.preventDefault();
        document.querySelector("main").scrollIntoView({
            behavior: "smooth"
        });
    }

    return (
        <a className="scroll-to-top" href="#nahoru" title="Nahoru" onClick={onScrollToTop}>Nahoru</a>
    )
}

export default ScrollToTop;
