const Images = () => {
    const runCallback = (cb) => {
        return cb();
    }

    return (
        <div className="images-background">
            {
                runCallback(() => {
                    const row = [];
                    for (var i = 1; i < 9; i++) {
                        row.push(
                            <div className="image" key={i}>
                                <picture>
                                    <source media="(min-width: 1921px)" srcSet={require(`../themes/frontend/img/640/${i}-640x640.webp`)} />
                                    <source media="(min-width: 1601px)" srcSet={require(`../themes/frontend/img/480/${i}-480x480.webp`)} />
                                    <source media="(min-width: 1441px)" srcSet={require(`../themes/frontend/img/400/${i}-400x400.webp`)} />
                                    <source media="(min-width: 1200px)" srcSet={require(`../themes/frontend/img/360/${i}-360x360.webp`)} />
                                    <source media="(min-width: 992px)" srcSet={require(`../themes/frontend/img/300/${i}-300x300.webp`)} />
                                    <source media="(min-width: 769px)" srcSet={require(`../themes/frontend/img/496/${i}-496x496.webp`)} />
                                    <source media="(min-width: 576px)" srcSet={require(`../themes/frontend/img/384/${i}-384x384.webp`)} />
                                    <source media="(min-width: 376px)" srcSet={require(`../themes/frontend/img/288/${i}-288x288.webp`)} />
                                    <img className="img" src={require(`../themes/frontend/img/188/${i}-188x188.webp`)} alt="Permanentní make-up Veronika" width="188" height="188" />
                                </picture>
                            </div>
                        )
                    }
                    return row;
                })
            }
        </div>
    );
}

export default Images;
