const Arrow = ({ url, text }) => {
    const onArrow = (e) => {
        e.preventDefault();
        const target = e.target.href.substring(e.target.href.lastIndexOf("#") + 1);
        const count = document.querySelector("#"+target).offsetTop - document.getElementsByTagName("header")[0].offsetHeight - window.scrollY;
        window.scrollBy({top: count, left: 0, behavior: "smooth"});
    }

    return (     
        <a className="arrow" href={url} title={text} onClick={onArrow}>{text}</a>
    );
}

export default Arrow;
