const Accordion = (e) => {
    document.querySelectorAll(".accordion span").forEach(e => {
        e.classList.remove("active");
    })
    var panel = e.target.nextElementSibling;
    if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
    } else {
        e.target.classList.add("active");
        document.querySelectorAll(".panel").forEach(e => {
            e.removeAttribute("style");
        })
        panel.style.maxHeight = panel.scrollHeight + 32 + "px";
    }
    setTimeout(function() {
        var bodyRect = document.body.getBoundingClientRect(),
            elemRect = e.target.getBoundingClientRect(),
            offset   = elemRect.top - bodyRect.top;
        window.scrollTo({top: offset - document.getElementsByTagName("header")[0].offsetHeight - 32, behavior: "smooth"});
    }, 300);
}

export default Accordion;
