import Use from "./Use.js";
import Accordion from "./Accordion.js";
import Images from "./Images";
import ArticleContent from "./ArticleContent";
import Arrow from "./Arrow";
import GalleryWrapper from "./GalleryWrapper";
import Gallery from "./Gallery";

const OdstraneniPMU = () => {
    return (
        <>
            <Use title="Odstranění PMU" description="Permanentní make-up Veronika Teplice provádí odstranění tetování pomocí kyseliny NPM Removal nebo kyseliny mléčné." /> 
            <article className="padding">
                <Images />
                <ArticleContent heading="Odstranění PMU" />
                <Arrow url="#odstraneni-pmu" text="Odstranění PMU" />
            </article>
            <article id="odstraneni-pmu" className="padding">
                <div className="article-content right padding" data-title="Důležité informace">
                    <h2>Odstranění PMU</h2>
                    <ul>
                        <li className="accordion">
                            <span onClick={Accordion}>Co je odstranění PMU?</span>
                            <ul className="panel">
                                <li>Je to technika, kterou se odstraňuje permanentní make-up pomocí roztoku Expert Removal.</li>
                                <li>Roztok Expert Removal se jemně zapraví pod kůži do požadovaného místa, kde k sobě přitahuje zbylé části starého pigmentu a pomocí osmózy ho vytlačí z kůže ven a poté mikrobiologickými postupy odstraní.</li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Jak bude probíhat má návštěva?</span>
                            <ul className="panel">
                                <li>S klientkou se nejdříve domluvíme na místě, které bude chtít ošetřit.</li>
                                <li>Poté dle potřeby aplikuji speciální roztok do kůže a ošetřím.</li>
                                <li>Následně dostane klientka pokyny, jak pečovat o ošetřené místo a mastičku na hojení.</li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Co bych měla před zákrokem vědět?</span>
                            <ul className="panel">
                                <li>Na proceduru přijďte bez doprovodu. Tím je myšleno bez dětí, kamarádů, zvířátek, kočárků a dalších rodinných i nerodinných příslušníků. :-)</li>
                                <li>Každé tetování je jiné a to samé platí i o jeho odstranění.</li>
                                <li>Zbavení se pigmentu není tak jednoduché, jako je jeho zapravení.</li>
                                <li>Je to proces, který potřebuje čas a trpělivost.</li>
                                <li>Nikdo Vám nikdy nezaručí počet sezení a konečný výsledek.</li>
                                <li>Vždy záleží na tom, jeké byly použity pigmenty nebo dokonce inkousty, do jaké hloubky byly zapraveny a jak dlouho už v kůži jsou.</li>
                                <li>Samotný zákrok trvá přibližně 30 minut.</li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Pro koho není aplikace vhodná?</span>
                            <ul className="panel">
                                <li>Pokud trpíte jakoukoliv z níže popsaných kontraindikací, není možné zákrok provést:
                                    <ul>
                                        <li>cukrovka s aplikací inzulínu</li>
                                        <li>kardiostimulátor</li>
                                        <li>srdeční choroby</li>
                                        <li>cévní choroby</li>
                                        <li>snížená srážlivost krve</li>
                                        <li>léky na ředění krve</li>
                                        <li>snížená imunita</li>
                                        <li>užívání antibiotik</li>
                                        <li>horečnaté stavy</li>
                                        <li>akutní onemocnění či nachlazení</li>
                                        <li>kožní nemoc - dermatitida, vyrážka (včetně akutní alergické) apod.</li>
                                        <li>rakovina v aktivním stádiu či rakovina kůže v jakékoliv fázi</li>
                                        <li>epilepsie</li>
                                        <li>HIV či jiná přenosná nemoc</li>
                                        <li>alergie na kosmetické látky, alergie na anestetika, alergie na prvotně aplikovaný pigment</li>
                                        <li>těhotenství a kojení</li>
                                        <li>příliš kapilární pleť</li>
                                        <li>keloidní či hypertrofické jizvy</li>
                                        <li>psychické onemocnění či celková psychická nepohoda či úzkost</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Bolí to?</span>
                            <ul className="panel">
                                <li>Vždy se snažím, aby se mé klientky cítily co nejpohodlněji.</li>
                                <li>Vzhledem k rychlosti zákroku se nejedná o nijak nepříjemnou proceduru.</li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Jak pečovat o ošetřené místo?</span>
                            <ul className="panel">
                                <li>Ať už se rozhodnete pro jakoukoliv proceduru, při které je porušována integrita kůže, tak berte, prosím, na vědomí, že se k ní vážou různá pravidla a omezení. Nedodržením pokynů můžete narušit proces hojení a negativně tak ovlivnit výsledný efekt.</li>
                                <li>Podrobné pokyny k péči o ošetřené místo ode mne obdržíte v písemné podobě.</li>
                            </ul>
                        </li>
                        <li className="accordion has-pricelist">
                            <span onClick={Accordion}>Kolik to stojí?</span>
                            <ul className="panel">
                                <li className="pricelist">
                                    <ul className="pricelist-inner">
                                        <li className="pricelist-row">
                                            <span>1 sezení</span>
                                            <span><strong>1000 Kč + mastička zdarma</strong></span>
                                        </li>
                                        <li>Pro klientky, které mají vytetovanou partii k odstranění pouze ode mne platí 50% sleva.</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <br /><br />
                    <h3>Nepřehlédněte!</h3>
                    <ul>
                        <li>Platba pouze v hotovosti!</li>
                    </ul>
                </div>
            </article>
            <GalleryWrapper>
                <Gallery lower="odstraneni-pmu" text="Odstranění PMU" count="4" /> 
            </GalleryWrapper>      
        </>
    );
}

export default OdstraneniPMU;
