import Use from "./Use.js";
import Images from "./Images";
import ArticleContent from "./ArticleContent";
import Arrow from "./Arrow";
import GalleryWrapper from "./GalleryWrapper";
import Gallery from "./Gallery";

const Omne = () => {
    return (
        <>
            <Use title="O mně" description="Jmenuji se Veronika Zelenková a PMU se věnuji již čtyři roky a za tu dobu jsem prošla různými školeními u známých osobností jako je Paulina Osinkowska, Christina Pidlozna z Academy-S a Veronika Fleiš z Fleiš Academy, kde jsem Gold Artist a získala jsem také spoustu certifikátů." /> 
            <article className="padding">
                <Images />
                <ArticleContent heading="O mně" />
                <Arrow url="#o-mne" text="O mně" />
            </article>
            <article id="o-mne" className="padding">
                <div className="article-content right padding" data-title="Důležité informace">
                    <h2>O mně</h2>
                    <ul>
                        <li>Moje jméno je Veronika Zelenková, s permanentním make-upem jsem se poprvé setkala v roce 2016 a od té doby se tetování stalo mou posedlostí.</li>
                        <li>Začala jsem se zajímat o různé školící akademie a o nejslavnější guru v PMU. Proto jsem absolvovala mnoho kurzů a školení, mj. u Pauliny Osinkowské, dále Christiny Pidlozne z Academy-S Sviatoslava Otchenashe a u Verici Blasko.</li>
                        <li>Navázala jsem Masterclassem u Ekateriny Antropové v Bratislavě a dále kurzem Hairstroke u Veroniky Petrás - La Majja.</li>
                        <li>Poté jsem studovala speciální techniku tetování rtů Frozen Lips u Agnes Permanent a pokračovala jsem zdokonalováním pudrové techniky u Nikoly Sedláčkové - IM Brows a Lucie Schreiber.</li>
                        <li>Navíc jsem se zúčastnila dvoudenního kurzu Perfect Lips u Maryny Bondar - La Skin a dále školení firmy Kwadron, kde mj. prezentovala svou techniku rtů i Marta Ziolkowska.</li>
                        <li>Úžasné školení speciální techniky rtů předvedla i Dolores Srok, které jsem měla tu čest asistovat. Spoustu vědomostí jsem získala také na workshopu o pigmentologii firmy Etalon v Praze.</li>
                        <li>Krásnou techniku pudrového obočí Velvet Brows jsem se školila u Magdaleny Telesińské, výherkyně světového šampionátu Wulop 2023.</li>
                        <li>V oblasti odstraňování PMU jsme absolvovala školení na všechny existující techniky. Roztoky solné, kyselinové a laser.</li>
                        <li>Také se specializuji na nastřelování náušnic pod anglickou firmou Estelle, kterou považuji za nejvíce profesionální firmu na trhu v oblasti piercingu.</li>
                        <li>Ta nejdůležitejší školení jsem ale absolvovala ve Fleiš Academy u Veroniky Fleiš, pro mne mistra v oboru. Zde jsem dosáhla titulů Artist, Silver Artist, Gold Artist, Master Assistant a nakonec té nejvyšší možné mety Master.</li>
                        <li>Jsem také jedna z mála držitelů log pro speciání autorské techniky, jako jsou Nude Lips a Smokey Eyes.</li>
                        <li>Mám za sebou mnoho vzdělávacích kongresů nejen u nás v Čechách - například to byl PMU LIVE pořádaný Leou Matyi nebo série Czech PMU Congress, které pořádá Veronika Fleiš, ale i v zahraničí. Např. v Mnichově a v Arménii jsem vystupovala jako NONSTOP SPEAKER a předváděla zde svou techniku tetování rtů Fruity Lips. Tuto techniku jsem prezentovala také na Wulop 2023 v Irsku, kam mě pozvala Aleksandra Pawlak.</li>
                        <li>Pro Fleiš Academy v současné době ještě navíc školím jak v České republice, tak na Slovensku.</li>
                        <li>Velkým mezníkem v mé kariéře bylo ohodnocení americkou firmou SKILLZ a nabídnutí spolupráce. Stala jsem se sponzorovaným SKILLZ MASTEREM a jsem na to moc pyšná.</li>
                        <li>V neposlední řadě jsem měla skvělou příležitost zúčastnit se prvního československého online PMU šampionátu a to v kategorii pudrové obočí, kde jsem obsadila 3. místo a v kategorii mikropigmentace rtů, kde jsem obsadila 2. místo.</li>
                        <li>Ale mým největším osobním úspěchem je účast na mezinárodním online šampionátu International Beauty Festival California USA San Francisco 2024, kde jsem obsadila hned 1. i 2. místo v kategorii Master - Lipstick Effect.</li>
                        <li>Za skvělé umístění považuji také 3. místo v mezinárodním online šampionátu Global Beauty Festival v kategorii Lipstick Effect.</li>
                        <li>V další neméně prestižní mezinárodní soutěži WBC Beauty Champ 2024 jsem obsadila úžasné 3. místo a to už v nejvyšší kategorii Professional - Permanent Lip Makeup.</li>
                        <li>Urvala jsem i 2. místo v soutěži PMU LIVE v kategorii Master Realistic Lips.</li>
                        <li>A co je pro mě opravdu velkou poctou, je ocenění od světové společnosti BEAUTY AWARDS, od které jsem získala 2 tituly - TOP 10 PERMANENT MAKEUP STYLISTS a TOP MASTER.</li>
                        <li>Tím to ale pro mne zdaleka nekončí. Neustále se vzdělávám a vzdělávat budu. Chci pro Vás jen to nejlepší.</li>
                        <li>Baví mě to, svou práci miluju a je to má vášeň!</li>
                    </ul>
                </div>
            </article>
            <GalleryWrapper>
                <Gallery lower="o-mne" text="O mně" count="32" />
            </GalleryWrapper>     
        </>
    );
}

export default Omne;
