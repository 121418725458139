import { Link } from "react-router-dom";
import Use from "./Use.js";
import Arrow from "./Arrow";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import reference from "../reference.json";

const Home = () => {
    const onClick = () => {
        document.getElementById("modal").classList.add("hidden");
    }

    return (
        <>
            <Use clas="homepage" title="Permanentní make-up Teplice" description="Veronika Zelenková, Masarykova tř. 2475/57, 415 01 Teplice, Ústecký kraj. Permanentní make-up je tetování speciálními pigmenty určených do oblasti obličeje jako jsou rty, oční linky a pudrové obočí. Připravuji pro Vás také metodu Hair Stroke, která je šetrnější než microblading obočí a napodobuje vzhled laminace obočí." />
            <article className="padding">
                <div className="article-image">
                    <img src={require("../themes/frontend/img/homepage/homepage.webp")} alt="Permanentní make-up Veronika" width="2560" height="1440" />
                </div>
                <div className="article-logo">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 486.32 316.44">
                        <path className="jPKBXXpD_0" d="m30.67,270.96h-4.57L0,207.68h8.52l19.86,49.48,19.86-49.48h8.52l-26.1,63.28Z"></path>
                        <path className="jPKBXXpD_1" d="m107.04,234.75v7.03h-27.86v22.15h32.34v7.03h-40.25v-63.28h40.25v7.03h-32.34v20.04h27.86Z"></path>
                        <path className="jPKBXXpD_2" d="m151.59,245.47h-14.59v25.49h-7.91v-63.28h26.81c10.46,0,18.9,8.44,18.9,18.9,0,9.05-6.33,16.52-14.76,18.46l16.96,25.93h-9.23l-16.17-25.49Zm3.43-30.76h-18.02v23.73h18.02c6.59,0,11.86-5.27,11.86-11.86s-5.27-11.86-11.86-11.86Z"></path>
                        <path className="jPKBXXpD_3" d="m256.52,239.32c0,18.19-14.76,32.96-32.96,32.96s-32.96-14.76-32.96-32.96,14.76-32.96,32.96-32.96,32.96,14.76,32.96,32.96Zm-7.91,0c0-14.33-10.72-25.93-25.05-25.93s-25.05,11.6-25.05,25.93,10.72,25.93,25.05,25.93,25.05-11.6,25.05-25.93Z"></path>
                        <path className="jPKBXXpD_4" d="m281.56,221.22v49.74h-7.91v-63.28h7.12l36.82,49.92v-49.92h7.91v63.28h-7.21l-36.74-49.74Z"></path>
                        <path className="jPKBXXpD_5" d="m344.83,270.96v-63.28h7.91v63.28h-7.91Z"></path>
                        <path className="jPKBXXpD_6" d="m372.08,270.96v-63.28h7.91v63.28h-7.91Zm10.02-33.31l23.64-29.97h10.02l-24.26,29.97,28.74,33.31h-10.46l-27.68-33.31Z"></path>
                        <path className="jPKBXXpD_7" d="m486.32,270.96h-8.44l-5.36-13.8h-28.83l-5.36,13.8h-8.44l26.01-63.28h4.39l26.01,63.28Zm-16.61-20.83l-11.6-29.71-11.6,29.71h23.2Z"></path>
                        <path className="jPKBXXpD_8" d="m71.72,299.67c0,3.84-3.1,6.94-6.94,6.94h-6.94v9.36h-2.9v-23.23h9.84c3.84,0,6.94,3.1,6.94,6.93Zm-2.9,0c0-2.42-1.94-4.35-4.35-4.35h-6.61v8.71h6.61c2.42,0,4.35-1.94,4.35-4.36Z"></path>
                        <path className="jPKBXXpD_9" d="m90.81,302.67v2.58h-10.23v8.13h11.87v2.58h-14.77v-23.23h14.77v2.58h-11.87v7.35h10.23Z"></path>
                        <path className="jPKBXXpD_10" d="m107.16,306.61h-5.35v9.36h-2.9v-23.23h9.84c3.84,0,6.94,3.1,6.94,6.93,0,3.32-2.32,6.06-5.42,6.78l6.23,9.52h-3.39l-5.94-9.36Zm1.26-11.29h-6.61v8.71h6.61c2.42,0,4.35-1.94,4.35-4.36s-1.94-4.35-4.35-4.35Z"></path>
                        <path className="jPKBXXpD_11" d="m147.9,292.74v23.23h-2.9v-18.26l-9.55,13.55h-.71l-9.55-13.55v18.26h-2.9v-23.23h2.61l10.19,14.1,10.19-14.1h2.61Z"></path>
                        <path className="jPKBXXpD_12" d="m174.08,315.96h-3.1l-1.97-5.06h-10.58l-1.97,5.06h-3.1l9.55-23.23h1.61l9.55,23.23Zm-6.1-7.65l-4.26-10.9-4.26,10.9h8.52Z"></path>
                        <path className="jPKBXXpD_13" d="m182.47,297.7v18.26h-2.9v-23.23h2.61l13.52,18.32v-18.32h2.9v23.23h-2.64l-13.48-18.26Z"></path>
                        <path className="jPKBXXpD_14" d="m218.81,302.67v2.58h-10.23v8.13h11.87v2.58h-14.77v-23.23h14.77v2.58h-11.87v7.35h10.23Z"></path>
                        <path className="jPKBXXpD_15" d="m229.81,297.7v18.26h-2.9v-23.23h2.61l13.52,18.32v-18.32h2.9v23.23h-2.64l-13.48-18.26Z"></path>
                        <path className="jPKBXXpD_16" d="m261.32,315.96h-2.9v-20.64h-6.84v-2.58h16.58v2.58h-6.84v20.64Z"></path>
                        <path className="jPKBXXpD_17" d="m311.18,292.74v23.23h-2.9v-18.26l-9.55,13.55h-.71l-9.55-13.55v18.26h-2.9v-23.23h2.61l10.19,14.1,10.19-14.1h2.61Z"></path>
                        <path className="jPKBXXpD_18" d="m337.37,315.96h-3.1l-1.97-5.06h-10.58l-1.97,5.06h-3.1l9.55-23.23h1.61l9.55,23.23Zm-6.1-7.65l-4.26-10.9-4.26,10.9h8.52Z"></path>
                        <path className="jPKBXXpD_19" d="m342.85,315.96v-23.23h2.9v23.23h-2.9Zm3.68-12.23l8.68-11h3.68l-8.9,11,10.55,12.23h-3.84l-10.16-12.23Z"></path>
                        <path className="jPKBXXpD_20" d="m378.81,302.67v2.58h-10.23v8.13h11.87v2.58h-14.77v-23.23h14.77v2.58h-11.87v7.35h10.23Z"></path>
                        <path className="jPKBXXpD_21" d="m405.58,292.74v14.93c0,5.19-4.23,8.77-9.42,8.77s-9.42-3.58-9.42-8.77v-14.93h2.9v14.93c0,3.61,2.9,6.19,6.52,6.19s6.52-2.58,6.52-6.19v-14.93h2.9Z"></path>
                        <path className="jPKBXXpD_22" d="m429.28,299.67c0,3.84-3.1,6.94-6.94,6.94h-6.93v9.36h-2.9v-23.23h9.84c3.84,0,6.94,3.1,6.94,6.93Zm-2.9,0c0-2.42-1.94-4.35-4.35-4.35h-6.61v8.71h6.61c2.42,0,4.35-1.94,4.35-4.36Z"></path>
                        <path className="jPKBXXpD_23" d="m204.85,62.39c-26.23,2.32-47.37,4.18-54.22-7.02-9.89-16.18,13.62-53.63,28.32-51.8,8.21,1.02,10.83,13.94,25.9,58.83,26.27,78.2,33.51,84.22,38.63,84.29,9.46.13,10.54-19.99,29.63-47.85,29.16-42.55,67.89-55.8,64.76-62.34-2.14-4.46-24.72,1.14-69.8,12.51-30.55,7.71-42.42,11.55-63.22,13.39Z"></path>
                    </svg>
                </div>
                <Arrow url="#permanentni-makeup" text="Permanentní make-up" />
            </article>
            <article id="permanentni-makeup" className="padding">
                <div className="article-content right padding" data-title="Důležité informace">
                    <h2>Permanentní make-up</h2>
                    <ul>
                        <li>Permanentní make-up, jak už sám název napovídá, je trvalý make-up. Ale za trvalý se považuje jen oproti klasickému líčení. Správně provedený permanentní make-up by měl časem z pokožky přirozeně vymizet a Vy byste tak měla mít možnost se znovu o podstoupení služby rozhodnout sama.</li>
                        <li>Berte, prosím, na vědomí, že permanentní make-up není náhražka za klasické líčení. I když jej spousta žen tak používá, ale měl by být jen jejím podkladem. Žena by měla mít takový PMU, při kterém bude i odlíčená vypadat krásně přirozeně.</li>
                        <li>Pokud se rozhodnete pro PMU, tak, prosím, respektujte, že vše má svá pravidla, která musíte dodržovat Vy i já. Odměnou Vám pak bude naturální vzhled i bez líčení. Svěží a mladistvější výraz, ušetřený čas i peníze při denním líčení.</li>
                        <li>Upravená žena se vždy cítí sebevědomě a to vypozoruje i její okolí, a tak k ní i přistupuje. Tak si to dopřejte a vychutnejte.</li>
                    </ul>
                    <br /><br />
                    <h3>Co Vám všechno nabízím?</h3>
                    <ul>
                        <li><Link to="/pudrove-oboci" title="Pudrové obočí">Pudrové obočí</Link></li>
                        <li><Link to="/rty" title="Rty">Rty</Link></li>
                        <li><Link to="/ocni-linky" title="Oční linky">Oční linky</Link></li>
                        <li><Link to="/mini-aquarelle-tattoo" title="Mini Aquarelle Tattoo">Mini Aquarelle Tattoo</Link></li>
                        <li><Link to="/odstraneni-pmu" title="Odstranění PMU">Odstranění PMU</Link></li>
                        <li><Link to="/nastrelovani-nausnic" title="Nastřelování náušnic">Nastřelování náušnic</Link></li>
                    </ul>
                </div>
            </article>
            <article className="padding">
                <div className="article-content left padding" data-title="Co o mně říkají?">
                    <Carousel showThumbs={false} useKeyboardArrows={true} showStatus={false} showIndicators={false} emulateTouch={true} autoPlay={true} interval={5000} infiniteLoop={true}>
                        {reference.array.map((_im, idx) =>
                            <div key={idx}>
                                <h2>{_im.title}</h2>
                                <p>&#8222;{_im.text}&#8221;</p>
                            </div>
                        )}
                    </Carousel>
                </div>
            </article>
            {false ?
                <div id="modal" className="modal padding">
                    <div className="modal-inner">
                        <div className="modal-content">
                            <img src={require("../themes/frontend/img/holiday.webp")} alt="Dovolená" width="3072" height="3072" />
                        </div>
                        <button className="modal-close" aria-label="Zavřít" onClick={onClick}>Zavřít</button>
                    </div>
                </div>
            : ""}
        </>
    );
}

export default Home;
