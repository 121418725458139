import Use from "./Use.js";
import Accordion from "./Accordion.js";
import Images from "./Images";
import ArticleContent from "./ArticleContent";
import Arrow from "./Arrow";
import GalleryWrapper from "./GalleryWrapper";
import Gallery from "./Gallery";

const NastrelovaniNausnic = () => {
    return (
        <>
            <Use title="Nastřelování náušnic" description="Permanentní make-up Veronika Teplice nabízí bezbolestné nastřelení náušnic od firmy Estelle v provedení z antialergenní leštěné oceli nebo pozlacené dvaceti čtyř karátovým zlatem a osazenými kamínky Swarovski." /> 
            <article className="padding">
                <Images />
                <ArticleContent heading="Nastřelování náušnic" />
                <Arrow url="#nastrelovani-nausnic" text="Nastřelování náušnic" />
            </article>
            <article id="nastrelovani-nausnic" className="padding">
                <div className="article-content right padding" data-title="Důležité informace">
                    <h2>Nastřelování náušnic Estelle</h2>
                    <ul>
                        <li className="accordion">
                            <span onClick={Accordion}>Co je Estelle?</span>
                            <ul className="panel">
                                <li>Estelle je firma sídlící ve Velké Británii.</li>
                                <li>Na trhu působí již více než 15 let a dodává své produkty do více než 60 zemí světa.</li>
                                <li>Je špičkou na světovém trhu v oblasti kvality, zpracování a nabídky barev a designů piercingových šperků.</li>
                                <li>Produkty značky Estelle se vyznačují bezkonkurenční kvalitou, která splňuje vysoké technikcé požadavky a nároky odborníků a zároveň klade důraz na designové provedení, škálu barev a vzorů požadovaných ze strany zákazníků.</li>
                                <li className="has-img"><img src={require("../themes/frontend/img/estelle.webp")} alt="Estelle" width="200" height="309" /></li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>S čím pracuji?</span>
                            <ul className="panel">
                                <li>Používám nejnovější piercingový systém, který klade důraz zejména na sterilitu.</li>
                                <li>Splňuje veškeré nároky dle hygienických směrnic ČR a EU.</li>
                                <li>Náušnice Estelle jsou vyrobeny z leštěné bílé oceli a chirurgické oceli pozlacené dvaceti čtyř karátovým žlutým zlatem o rizosti 999/1000 (pozlacené jsou i puzety).</li>
                                <li>Neobsahují NIKL!!!</li>
                                <li>Nášnice jsou osazeny nádhernými kamínky z kolekce Swarovski Elements.</li>
                                <li>Vzorník nabízených náušnic je k dispozici u mě ve studiu.</li>
                                <li className="has-img"><img src={require("../themes/frontend/img/swarovski.webp")} alt="Swarovski Elements" width="315" height="125" /></li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Kam se náušnice nastřelují?</span>
                            <ul className="panel">
                                <li>Náušnice nastřeluji pouze do uší a to jen do jejich měkkých částí.</li>
                                <li>Pokud si přejete nastřelit šperk do chrupavky, kontaktujte naší piercerku Danu na telefonním čísle 737 841 135.</li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Jak nastřelování probíhá?</span>
                            <ul className="panel">
                                <li>Nejprve jsou řádně zkontrolovány ušní boltce a po následné konzultaci je pomocí medicínského fixu naznačeno místo nastřelení.</li>
                                <li>Poté se ucho dezinfikuje a nastřelí samotný šperk do vyznačeného místa nastřelovací pistolí.</li>
                                <li>Upozorňuji, že nastřeluji pouze do měkkých částí ucha (ne do chrupavky).</li>
                                <li>Po nastřelení se zkontroluje stav vpichu a uzávěr náušnice.</li>
                                <li>Před odchodem ze studia je klientka řádně poučena o správné péči, možných komplikacích a následné výměně. To vše jí je předáno i v tištěné podobě.</li>
                                <li>Náusnice Estelle jsou určeny i k trvalému nošení a není nutné je měnit za jiné.</li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Kdy je vhodné náušnice nastřelit?</span>
                            <ul className="panel">
                                <li>Náušnice je vhodné nastřelit dětem zhruba od čtyř měsíců věku (jsou již dobře vyvinuté ušní lalůčky).</li>
                                <li>Nastřelení nemá návaznost na očkování, veškerý materiál je sterilní a není proto nutné očkování absolvovat.</li>
                                <li>Nastřelení může podstoupit každý po osmnáctém roku věku.</li>
                                <li>Pokud klientce není ještě 18 let, musí být nastřelení provedeno za přítomnosti a souhlasu plnoletého.</li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Komu není vhodné náušnice nastřelit?</span>
                            <ul className="panel">
                                <li>dětem do čtyř měsíců věku</li>
                                <li>při výskytu útvaru, který na ucho nepatří (tj. výrůstek, ucpaná mazová žláza, bradavice)</li>
                                <li>lidem trpícím poruchou krevní srážlivosti</li>
                                <li>při akutním onemocnění, nachlazení, teplotě atd.</li>
                                <li>pacientům užívajícím léky na ředění krve</li>
                                <li>při alergiích na chirurgickou ocel či zlato</li>
                                <li>pacientům užívajícím antibiotika</li>
                            </ul>
                        </li>
                        <li className="accordion has-pricelist">
                            <span onClick={Accordion}>Kolik to stojí?</span>
                            <ul className="panel">
                                <li className="pricelist">
                                    <ul className="pricelist-inner">
                                        <li className="pricelist-row">
                                            <span>1x Náušnice CHIRURGICKÁ OCEL (ANTIALERGENNÍ)<br />BEZ KAMÍNKU nebo S KAMÍNKEM SWAROVSKI</span>
                                            <span><strong>500 Kč</strong></span>
                                        </li>   
                                        <li className="pricelist-row">
                                            <span>1x Náušnice CHIRURGICKÁ OCEL POZLACENÁ 24KT ZLATEM<br />BEZ KAMÍNKU nebo S KAMÍNKEM SWAROVSKI</span>
                                            <span><strong>500 Kč</strong></span>
                                        </li>                                   
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="accordion">
                            <span onClick={Accordion}>Dárkové poukazy</span>
                            <ul className="panel">
                                <li>Nově u mě můžete zakoupit dárkové poukazy na nastřelování náušnic.</li>
                                <li>Dárkový poukaz vždy platí na nastřelení jedné náušnice dle vlastního výběru. Samozřejmě lze zakoupit více poukazů.</li>
                                <li>Platnost poukazu je 5 měsíců od data zakoupení.</li>
                            </ul>
                        </li>
                    </ul>
                    <br /><br />
                    <h3>Nepřehlédněte!</h3>
                    <ul>
                        <li>Platba pouze v hotovosti!</li>
                    </ul>
                </div>
            </article>
            <GalleryWrapper>
                <Gallery lower="nastrelovani-nausnic" text="Nastřelování náušnic" count="16" />   
            </GalleryWrapper>    
        </>
    );
}

export default NastrelovaniNausnic;
