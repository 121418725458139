import Use from "./Use.js";
import Images from "./Images";
import ArticleContent from "./ArticleContent";
import Arrow from "./Arrow";

const DarkovePoukazy = () => {
    return (
        <>
            <Use title="Dárkové poukazy" description="Pokud chcete zakoupit dárkový poukaz neboli voucher na permanentní make-up a to pudrové obočí a stínované rty, volejte na telefonní číslo 728313090." /> 
            <article className="padding">
                <Images />
                <ArticleContent heading="Dárkové poukazy" />
                <Arrow url="#darkove-poukazy" text="Dárkové poukazy" />
            </article>
            <article id="darkove-poukazy" className="padding">
                <div className="article-content left padding" data-title="Důležité informace">
                    <h2>Dárkové poukazy</h2>
                    <ul>
                        <li>Dárkové poukazy na <strong>pudrové obočí</strong> a <strong>stínované rty</strong> u mě můžete zakoupit po celý rok.</li>
                        <li>A jako novinka jsou pro Vás k dispozici také dárkové poukazy na <strong>nastřelování náušnic</strong>.</li>
                        <li>Děkuji za Vaši důvěru a zakoupení poukázky na mé služby.</li>                      
                    </ul>
                    <br /><br />
                    <h3>Podmínky pro použití dárkového poukazu</h3>
                    <ul>
                        <li>Platnost všech poukázek je 5 měsíců od data zakoupení. Pokud se objednáte a budete chtít termín procedury změnit, musíte tak učinit minimálně 5 dní před smluveným termínem. Jestliže tak neučiníte, bude Vám účtována částka 500 Kč.</li>
                        <li>V ceně poukázky <strong>NENÍ ZAHRNUTA</strong> cena korence. Je tomu tak proto, že korekce nemusí být vždy potřeba.</li>
                        <li>Pokud nebudete moci poukázku z jakéhokoliv důvodu využít nebo Vám nebude moci být služba provedena (např. ze zdravotních důvodů), můžete ji darovat nebo prodat. Peníze za poukázky nevracím.</li>
                        <li>Poukázku musíte předložit při smluvené návštěvě, jinak Vám bude služba účtována a peníze vráceny až po předložení platné poukázky.</li>
                        <li>I v případě, že máte dárkový poukaz, respektujte, prosím, objednávací lhůty. Dárkový poukaz neznamená dřívější termín.</li>
                        <li>Pokud na proceduru nedorazíte a to bez omluvy nejpozději 48 hodin před smluveným termínem, bude Vám při domluvě nového termínu účtován poplatek ve výši 1500 Kč, který uhradíte předem na účet.</li>
                        <li>V případě poukazu na nastřelování náušnic je to 200 Kč.</li>
                    </ul>
                    <br /><br />
                    <h3>Podmínky pro dárkové poukazy vydražené v AniDef, z.s.</h3>
                    <ul>
                        <li>Platnost poukázky je do 31. 12. 2023</li>
                        <li>Pokud nebudete moci poukázku z jakéhokoliv důvodu využít nebo Vám nebude moci být služba provedena (např. ze zdravotních důvodů), můžete ji darovat nebo prodat. Peníze za poukázky nevracím.</li>
                        <li>Poukázku musíte předložit při smluvené návštěvě, jinak Vám bude služba účtována a peníze vráceny až po předložení platné poukázky.</li>
                        <li>I v případě, že máte dárkový poukaz, respektujte, prosím, objednávací lhůty. Dárkový poukaz neznamená dřívější termín.</li>
                        <li>Pokud na proceduru nedorazíte a to bez omluvy nejpozději 48 hodin před smluveným termínem, bude Vám při domluvě nového termínu účtován poplatek ve výši 1000 Kč, který uhradíte předem na účet.</li>
                    </ul>
                    <br /><br />
                    <h3 className="red">Ukončení platnosti STARÝCH poukazů</h3>
                    <ul>
                        <li>Milé zákaznice, ukončuji platnost starých poukazů, tzn. poukazů zakoupených před 1. 4. 2021.</li>
                        <li>Kvůli covidové situaci jsem nechávala prodlouženou platnost a měly jste tak možnost poukázku využít třeba i po dvou letech. Myslím, že to bylo z mé strany fér a tu možnost jste měly opravdu všechny. Je na čase platnost ukončit.</li>
                        <li>Dotazy ohledně poukázek budu řešit pouze telefonicky.</li>
                        <li>Všem děkuji za pochopení.</li>
                    </ul>
                </div>
            </article>
        </>
    );
}

export default DarkovePoukazy;
