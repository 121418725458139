import Use from "./Use.js";
import Images from "./Images";
import ArticleContent from "./ArticleContent";
import Arrow from "./Arrow";

const ObchodniPodminky = () => {
    return (
        <>
            <Use title="Obchodní podmínky" description="" /> 
            <article className="padding">
                <Images />
                <ArticleContent heading="Obchodní podmínky" />
                <Arrow url="#obchodni-podminky" text="Obchodní podmínky" />
            </article>
            <article id="obchodni-podminky" className="padding">
                <div className="article-content left padding" data-title="Důležité informace">
                    <h2>Obchodní podmínky</h2>
                    <ul>
                        <li>Obchodní podmínky budou za nedlouho doplněny.</li>
                    </ul>
                </div>
            </article>
        </>
    );
}

export default ObchodniPodminky;
