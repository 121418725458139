import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async"; 
import CookieConsent from "react-cookie-consent";
import { isMobile } from "react-device-detect";
import Header from "./components/Header";
import ScrollToTop from "./components/ScrollToTop";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Omne from "./components/Omne";
import Poradna from "./components/Poradna";
import PudroveOboci from "./components/PudroveOboci";
import Rty from "./components/Rty";
import OcniLinky from "./components/OcniLinky";
import MiniAquarelleTattoo from "./components/MiniAquarelleTattoo";
import OdstraneniPMU from "./components/OdstraneniPMU";
import NastrelovaniNausnic from "./components/NastrelovaniNausnic";
import Cenik from "./components/Cenik";
import DarkovePoukazy from "./components/DarkovePoukazy";
import ObchodniPodminky from "./components/ObchodniPodminky";
import Kontakt from "./components/Kontakt";
import NotFound from "./components/NotFound";

const App = () => {
  useEffect(() => {
    var myResizeFunction = function() {
      var vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", vh + "px");

      var scroller = document.documentElement;
      requestAnimationFrame(()=>{
      scroller.style
        .setProperty(
          "--vw", 
          scroller.clientWidth + "px"
        );
      });
    };
  
	  if(isMobile) {
		  myResizeFunction();
	  } else {
      if(window.attachEvent) {
        window.attachEvent("onresize", function() {
          myResizeFunction();
        });
      }
      else if(window.addEventListener) {
        window.addEventListener("resize", function() {
          myResizeFunction();
        }, true);
      }
      else {}
    
      if(typeof(Event) === "function") {
        window.dispatchEvent(new Event("resize"));
      } else {
        var evt = window.document.createEvent("UIEvents");
        evt.initUIEvent("resize", true, false, window, 0);
        window.dispatchEvent(evt);
      }
	  }

    if(isMobile) {
      document.getElementsByTagName("html")[0].classList.add("touch")
    } else {
      document.getElementsByTagName("html")[0].classList.remove("touch")
    }

    window.onscroll = function() {
      if(window.pageYOffset >= window.outerHeight) {
        document.getElementsByClassName("scroll-to-top")[0].classList.add("visible")
      } else {
        document.getElementsByClassName("scroll-to-top")[0].classList.remove("visible")
      }
      if(window.pageYOffset >= document.getElementsByTagName("header")[0].offsetHeight) {
        document.getElementsByTagName("header")[0].classList.add("scrolled")
      } else {
        document.getElementsByTagName("header")[0].classList.remove("scrolled")
      }
    }

  }, [])
  
  return (
    <HelmetProvider>  
      <BrowserRouter>
        <>
          <div className="wrapper">
            <div className="inner">
              <Header />
              <main className="main">
                <Routes>   
                  <Route path="/" exact element={<Home />} />
                  <Route path="/o-mne" element={<Omne />} />
                  <Route path="/poradna" element={<Poradna />} />
                  <Route path="/pudrove-oboci" element={<PudroveOboci />} />
                  <Route path="/rty" element={<Rty />} />
                  <Route path="/ocni-linky" element={<OcniLinky />} />
                  <Route path="/mini-aquarelle-tattoo" element={<MiniAquarelleTattoo />} />
                  <Route path="/odstraneni-pmu" element={<OdstraneniPMU />} />
                  <Route path="/nastrelovani-nausnic" element={<NastrelovaniNausnic />} />
                  <Route path="/cenik" element={<Cenik />} />
                  <Route path="/darkove-poukazy" element={<DarkovePoukazy />} />
                  <Route path="/obchodni-podminky" element={<ObchodniPodminky />} />
                  <Route path="/kontakt" element={<Kontakt />} />
                  <Route path="*" element={<NotFound />} />
                </Routes> 
                <ScrollToTop />
              </main>
              <Footer />
            </div>
          </div>
          <CookieConsent
            buttonText="Povolit vše"
            declineButtonText="Zakázat vše"
            ariaAcceptLabel="Povolit vše"
            ariaDeclineLabel="Zakázat vše"
            cookieName="cookie_consent"
            enableDeclineButton
            flipButtons
            disableStyles
            onAccept={() => {
              const commentStart = document.createComment(" Google Tag Manager ");
              const commentEnd =  document.createComment(" End Google Tag Manager ");
              const element = document.createElement("script");
              element.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-MH65ZF9');";
              document.body.appendChild(commentStart);
              document.body.appendChild(element);
              document.body.appendChild(commentEnd);
            }}
          >
            Kliknutím na <strong>Povolit vše</strong> nám umožníte použití cookies pro funkční, analytické a marketingové účely na tomto zařízení. S Vaším souhlasem budeme používat cookies, díky kterým lépe poznáme, co Vás zajímá. Budeme Vám ukazovat informace, které hledáte a nebudeme Vás obtěžovat těmi ostatními. Jak na našem webu, tak i v reklamě na internetu. Na našem webu používáme i cookies, které jsou nezbytné, aby stránky fungovaly správně a rychleji jste se na nich zorientovali.
          </CookieConsent>
        </>
      </BrowserRouter>
    </HelmetProvider>  
  );
}

export default App;
